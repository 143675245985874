import axios from 'axios';
import { Category, Tournament, Event, Market, BetType, BetSlipItem, Silk, EventBetType, BetTypeSpecific, EventLeg, 
  MultiBetSlipItem, Bets,PlaceBetMultiResponse, Session,
  EventStatus, Balance} from '../types/Types';

const API_URL = 'https://punter.bethostdev.co.za/api';

export const fetchCategory = async (dataProviderId:number): Promise<Category[]> => {
  try { 
    const response = await axios.get(`${API_URL}/event/category?dataProviderId=${dataProviderId}&pageNo=1&pageSize=100&eventStatusId=1`);
   return response.data as Category[]; 
    
  } catch (error) {
    console.error('Error fetching Categorys:', error);
    throw error;
  }
};

export const fetchTournament = async (dataProviderId:number, categoryId:number): Promise<Tournament[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/tournament?dataProviderId=${dataProviderId}&sportId=2&categoryId=${categoryId}&pageNo=1&pageSize=100&eventStatusId=1`);            
      var tourns  = response.data as Tournament[];
      

      tourns.forEach(async (tourn)=>{      
        tourn.events = await fetchEvent(dataProviderId, tourn.tournamentId)
        tourn.events.sort((a, b) => a.eventNumber - b.eventNumber);
      });
      
      return tourns;
    } catch (error) {
      console.error('Error fetching Tournaments:', error);
      throw error;
    }
  };

  export const fetchCategoryTournament = async (dataProviderId:number): Promise<Tournament[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/tournament?dataProviderId=${dataProviderId}&pageNo=1&pageSize=100&eventStatusId=1`);            
      var tourns  = response.data as Tournament[];

      tourns.forEach(async (tourn)=>{      
        tourn.events = await fetchEvent(dataProviderId, tourn.tournamentId)
      });
      
      return tourns;
    } catch (error) {
      console.error('Error fetching Category Tournaments:', error);
      throw error;
    }
  };

  export const fetchAllCategoryAndTournaments = async (dataProviderId:number): Promise<Tournament[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/tournament?dataProviderId=${dataProviderId}&pageNo=1&pageSize=100&eventStatusId=1`);            
      var tourns  = response.data as Tournament[]

      tourns.forEach(async (tourn)=>{      
       tourn.events = await fetchEvent(dataProviderId, tourn.tournamentId)
     });

      return tourns;
    } catch (error) {
      console.error('Error fetching all Category and Tournaments:', error);
      throw error;
    }
  };

   export const fetchUpcomingTournament = async (dataProviderId:number, tournamentDate:string|Date, tournamentEndDate: string|Date): Promise<Tournament[]> => {
     try { 
       const response = await axios.get(`${API_URL}/event/tournament?dataProviderId=${dataProviderId}&startDate=${tournamentDate}&endDate=${tournamentEndDate}&pageNo=1&pageSize=100&eventStatusId=1`);            
       var tourns  = response.data as Tournament[]

       tourns.forEach(async (tourn)=>{      
        tourn.events = await fetchEvent(dataProviderId, tourn.tournamentId)
      });

       return tourns;
     } catch (error) {
       console.error('Error fetching Upcoming Tournaments:', error);
       throw error;
     }
   };
  
  export const fetchEvent = async (dataProviderId:number, tournamentId:number): Promise<Event[]> => {
    try { 
      
      const response = await axios.get(`${API_URL}/event/event?dataProviderId=${dataProviderId}&tournamentId=${tournamentId}`);    
      const events = response.data as Event[];
      events.sort((a, b) => a.eventNumber - b.eventNumber);

      return events;
      
    } catch (error) {
      console.error('Error fetching Event:', error);
      throw error;
    }
  };

  export const fetchMarket = async (dataProviderId:number, eventId:number, betTypeId:number): Promise<Market[]> => {
    try { 

      const response = await axios.get(`${API_URL}/event/market?dataProviderId=${dataProviderId}&betTypeId=${betTypeId}&eventId=${eventId}`); 
      var mkt  = response.data as Market[];  
      
      mkt.forEach(async (mkt) => {
        mkt.silk = await fetchSilk(dataProviderId, mkt.eventId, mkt.betTypeId)
      });
      
      return mkt;
    } catch (error) {
      console.error('Error fetching Market:', error);
      throw error;
    }
  };

  export const fetchBetType = async (dataProviderId:number): Promise<BetType[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/bettype?dataProviderId=${dataProviderId}`);            
      return response.data as BetType[];
    } catch (error) {
      console.error('Error fetching BetType:', error);
      throw error;
    }
  };

  export const fetchSilk = async (dataProviderId:number, eventId:number, betTypeId:number): Promise<Silk[]> => {
    try { 

      const response = await axios.get(`${API_URL}/event/silk?dataProviderId=${dataProviderId}&betTypeId=${betTypeId}&eventId=${eventId}`);            
      
      return response.data as Silk[];
    } catch (error) {
      console.error('Error fetching Silks:', error);
      throw error;
    }
  };

  export const fetchEventBetType = async (dataProviderId:number, eventId:number): Promise<EventBetType[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/eventBetType?dataProviderId=${dataProviderId}&eventId=${eventId}`);            
      
      return response.data as EventBetType[];
    } catch (error) {
      console.error('Error fetching EventBetType:', error);
      throw error;
    }
  };

  export const fetchBetTypeSpecific = async ( betTypeId:number): Promise<BetTypeSpecific[]> => {
    try { 

      const response = await axios.get(`${API_URL}/event/betTypeSpecific?betTypeId=${betTypeId}`);            
      
      return response.data as BetTypeSpecific[];
    } catch (error) {
      console.error('Error fetching BetTypeSpecific:', error);
      throw error;
    }
  };

  export const fetchEventLeg = async (eventGroupId?:number): Promise<EventLeg[]> => {
    try { 
      const response = await axios.get(`${API_URL}/event/exotic?eventGroupId=${eventGroupId}`);       

      return response.data as EventLeg[];
    } catch (error) {
      console.error('Error fetching EventLeg:', error);
      throw error;
    }
  };

  export const placeBet = async (betSlipItem: BetSlipItem, token:string) => {
    try {
      const response = await axios.post(
        `${API_URL}/transaction/placebet`,
        betSlipItem,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      return response.data as PlaceBetMultiResponse;
    } catch (error) {
      console.error('Error placing bet:', error);
      throw error;
    }
  };

  export const multiPlaceBet = async (multiBetSlipItem: MultiBetSlipItem, token:string) => {
    try {
      const response = await axios.post(
        `${API_URL}/transaction/placebet`,
        multiBetSlipItem,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
 
      return response.data as PlaceBetMultiResponse;

    } catch (error) {
      console.error('Error placing bet:', error);
      throw error;
    }
  };

  export const fetchBets = async (betDate: Date|string, token:string): Promise<Bets[]> => {
    try {
      const response = await axios.get(`${API_URL}/transaction/betbydate?betDate=${betDate}`, {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const sortedData = response.data.sort((a: Bets, b: Bets) => b.betId - a.betId);
      return sortedData as Bets[];
    } catch (error) {
      console.error('Error placing bet:', error);
      throw error;
    }
  };

  export const placeYankeeBet = async (multibetSlipItem: {}, token:string) => {
    try {
      const response = await axios.post(
        `${API_URL}/transaction/placebet`,
        multibetSlipItem,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
 
      return response.data as PlaceBetMultiResponse;

    } catch (error) {
      console.error('Error placing bet:', error);
      throw error;
    }
  };

   export const fetchSession = async (operatorKey:string, playerUniqueId:string): Promise<Session> => {
       const response = await axios.get(`${API_URL}/punter/session?operatorkey=${operatorKey}&playeruniqueid=${playerUniqueId}`);      
       return response.data as Session;
    
   };

   export const fetchEventStatus = async ():Promise<EventStatus[]> =>{
    try { 
      const response = await axios.get(`${API_URL}/event/eventstatus`);            
      
      return response.data as EventStatus[];
    } catch (error) {
      console.error('Error fetching EventStatus:', error);
      throw error;
    }
  };

  export const fetchBalance = async (playerUniqueId:string): Promise<Balance> => {
    try { 
      const response = await axios.get(`https://bonus.bethostdev.co.za/Balance?PunterId=${playerUniqueId}`);            
      return response.data as Balance;
    } catch (error) {
      console.error('Error fetching balance:', error);
      throw error;
    }
  };

  export const updateBalance = async (playerUniqueId:string, betReference:string, currentBalance: number, stake:number): Promise<string> => {
    try { 
      const payload = `{
                        "idempotencyId": "${betReference}",
                        "date": "2024-06-24T13:14:07.545Z",
                        "punterId": "${playerUniqueId}",
                        "reference": "${betReference}",
                        "betReference": "${betReference}",
                        "transactionCategory": "stake",
                        "amount": ${stake},
                        "balance": ${currentBalance}
                      }`;

      const response1 = await axios.post(
                        `https://bonus.bethostdev.co.za/Process`,
                        payload,
                        {
                          headers: {
                            'Content-Type': 'application/json' 
                          },
                        }
                      ); 

      //const response = await axios.post(`https://bonus.bethostdev.co.za/Balance?PunterId=${playerUniqueId}`);            
      return "";
    } catch (error) {
      console.error('Error updating balance:', error);
      throw error;
    }
  };


  export const punterService = {fetchEvent}