import { Market} from "../types/Types";

  export const generateFixedBettingGrid = (markets: Market[], placeOdd:Market[], betTypeId?:number, betTypeSpecificId?:number): Market[] => {

    
    if(betTypeId ===1) 
    { //SP Win 
        if(betTypeSpecificId === 3 ){
            markets.forEach((mk) => {
                mk.columns = [{
                    columnName: " ",
                    columnNumber: 0,
                    columnOdd: "SP" ,
                    columnMarketNumber: mk.marketNumber,
                }] 
            });
        }

        //Open Win 
       else if(betTypeSpecificId === 2 ){
          markets.forEach((mk) => {
              mk.columns = [{
                  columnName: " ",
                  columnNumber: 0,
                  columnOdd:"OW",
                  columnMarketNumber: mk.marketNumber,
              }] 
          });
        }

       // Fixed Win And Fixed Place
       else {
        markets.forEach((mk) => {
          mk.columns = [
            {   
              columnName: "Opening",
              columnNumber: 0,
              columnOdd: mk.currentOdd,
              columnMarketNumber: mk.marketNumber,
              isSelected: false,
              isEnabled: false,
              isFloating: false,

            },
            {
              columnName: "Previous",
              columnNumber: 1,
              columnOdd: mk.previousOdd,
              columnMarketNumber: mk.marketNumber,
              isSelected: false,
              isEnabled: false,
              isFloating: false,
            },
            {
              columnName: "Win",
              columnNumber: 2,
              columnOdd: mk.currentOdd,
              columnMarketNumber: mk.marketNumber,
              isSelected: false,
              isEnabled: false,
              isFloating: false,
              buttons:1,
              columnBetTypeId:1,
              columnBetTypeSpecificId:1,
              columnClickedName: "Fixed Win"
            },

             {
               columnName: "Place",
               columnNumber: 3,
               columnOdd: isNaN(placeOdd.filter(item=>item.marketNumber ===mk.marketNumber)[0]?.currentOdd)?0:placeOdd.filter(item=>item.marketNumber ===mk.marketNumber)[0]?.currentOdd,
               columnMarketNumber: mk.marketNumber,
               isSelected: false,
               isEnabled: false,
               isFloating: false,
               buttons:1,
               columnBetTypeId:13,
               columnBetTypeSpecificId:1,
               columnClickedName: "Fixed Place"
             },
          ];
        });
      }; 
      
    }

     else {
      markets.forEach((mk) => {
        mk.columns = [
          {   
            columnName: "Opening",
            columnNumber: 0,
            columnOdd: mk.currentOdd,
            columnMarketNumber: mk.marketNumber,
            isSelected: false,
            isEnabled: false,
            isFloating: false,
          },
          {
            columnName: "Previous",
            columnNumber: 1,
            columnOdd: mk.previousOdd,
            columnMarketNumber: mk.marketNumber,
            isSelected: false,
            isEnabled: false,
            isFloating: false,
          },
          {
            columnName: "Win",
            columnNumber: 2,
            columnOdd: mk.currentOdd,
            columnMarketNumber: mk.marketNumber,
            isSelected: false,
            isEnabled: false,
            isFloating: false,
            buttons:1,
            columnBetTypeId:1,
            columnBetTypeSpecificId:1,
            columnClickedName: "Fixed Win"
          },
          {
            columnName: "Place",
            columnNumber: 3,
            columnOdd: 5,
            columnMarketNumber:isNaN(placeOdd.filter(item=>item.marketNumber ===mk.marketNumber)[0]?.currentOdd)?0:placeOdd.filter(item=>item.marketNumber ===mk.marketNumber)[0]?.currentOdd,
            isSelected: false,
            isEnabled: false,
            isFloating: false,
            buttons:1,
            columnBetTypeId:13,
            columnBetTypeSpecificId:1,
            columnClickedName: "Fixed Place"
          },
        ];
      });
    }; 

    return markets;
  };

  
