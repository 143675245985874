import React, { useState, useContext, useEffect } from "react";
import { Bets } from "../../types/Types";
import { fetchBets } from "../../services/PunterService";
import xmark from "../../Icons/xmark.svg";
import { AppContext } from "../../services/ContextProvider";

const MyBetsComponent = () => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [betsList, setBetList] = useState<Bets[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | string>('');
  const [openBetNumbers, setOpenBetNumbers] = useState<number[]>([]);
  const [showLegs, setShowLegs] = useState<Record<number, boolean>>({});
  const [betNumber, setBetNumber] = useState<number>(0);

  useEffect(() => {
    setBetList([]);
    if (appStateStore.currentSession?.jwtSessionToken !== null) {
      const handleMyBets = async () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        const selectDate = new Date(selectedDate);
        const selectedYear = selectDate.getFullYear();
        const selectedMonth = String(selectDate.getMonth() + 1).padStart(2, '0');
        const selectedDay = String(selectDate.getDate()).padStart(2, '0');
        const SelectedString = `${selectedYear}-${selectedMonth}-${selectedDay}`;

        const response = await fetchBets(selectedDate !== '' ? SelectedString : dateString, String(appStateStore.currentSession?.jwtSessionToken));
        setBetList(response);
      };

      handleMyBets();
    }
  }, [appStateStore.isPopUpMyBets, selectedDate]);

  const handleRemoveMyBetsPopUP = async () => {
    setAppStateStore((appStateStore) => ({
      ...appStateStore,
      isPopUpMyBets: false
    }));
  };

  const handleShowLegs = (betId: number) => {
    setBetNumber(betId);
    setShowLegs((prevShowLegs) => ({
      ...prevShowLegs,
      [betId]: !prevShowLegs[betId]
    }));
    setOpenBetNumbers((prevOpenBetNumbers) => {
      if (prevOpenBetNumbers.includes(betId)) {
        return prevOpenBetNumbers.filter(num => num !== betId);
      } else {
        return [...prevOpenBetNumbers, betId];
      }
    });
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = new Date(event.target.value);
    if (isNaN(inputDate.getTime())) { 
        return;
    }
    const formattedDate = formatDate(inputDate);
    setSelectedDate(new Date(formattedDate));
};

  const betStatusesList = [
    { "betStatusId": 1, "betStatusName": "Pending" },
    { "betStatusId": 2, "betStatusName": "Won" },
    { "betStatusId": 3, "betStatusName": "Lost" },
    { "betStatusId": 4, "betStatusName": "Refunded" },
    { "betStatusId": 5, "betStatusName": "Cancelled" }
  ];

  return (
    <div className="w-full font-inter lg:text-base text-[10px] bg-gray-50">
      <div className="w-full text-white bg-blue-500 p-2 flex">
        <div className="w-4/5 text-2xl">
          <div>
            MY BETS
          </div>
          <div className="lg:hidden text-base">
            <label htmlFor="date" className="text-white">Select Date: </label>
            <input
              className="text-black"
              type="date"
              id="date"
              name="date"
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="w-1/5 text-right p-2 flex">
          <div className="w-2/3  flex hidden md:block">
            <label htmlFor="date" className="text-white">Select Date: </label>
            <input
              className="text-black"
              type="date"
              id="date"
              name="date"
              onChange={handleDateChange}
            />
          </div>
          <div className="lg:w-1/3 w-full lg:text-right items-end">
            <button onClick={handleRemoveMyBetsPopUP}>
              <img src={xmark} className="w-5 h-full text-white " alt="x" />
            </button>
          </div>
        </div>
      </div>
      {betsList.length > 1 &&
        betsList.map((bt) => (
          <div key={bt.betId} >
            <div className={`w-full flex ${'border-b-2'}`} onClick={() => handleShowLegs(bt.betId)}>
              <div className={`lg:w-5/6 w-4/5 p-2 border-r-2`}>
                <div>
                  <div className="">
                    <p>{String((new Date(bt.betDate)).toLocaleDateString([], { hour12: false })).split('T')[0]}
                      <span ><span className="px-1">|</span>{String(new Date(bt.betDate).toLocaleTimeString([], { hour12: false })).split(':')[0]}
                        :{String(new Date(bt.betDate).toLocaleTimeString()).split(':')[1]}</span>
                    </p>
                  </div>
                  <div className="py-1  flex">
                    <div className="">
                      {bt.betSummary.split(',')[0]} <span className="px-1">
                      - {bt.betSummary.split(',')[0]}</span> <span className="px-1">-</span> {bt.betSummary.split(',')[1]}<span className="px-1">|</span>Bet Id: {bt.betId}
                    </div>
                  </div>
                  <div className="">
                    Odds: {bt.currentOdd !== null ? bt.currentOdd?.toFixed(2) : bt.odd.toFixed(2)}<span className="px-1">|</span>
                    Stake: {bt.stake.toFixed(2)}<span className="px-1">|</span> Payout: {bt.payout.toFixed(2)}
                  </div>
                </div>
              </div>
              {betStatusesList && betStatusesList.filter((bts) => bts.betStatusId === (bt.currentBetStatusId === null ? bt.betStatusid : bt.currentBetStatusId))
                .map((bts) => (
                  <div className={`lg:w-1/6 w-1/5 text-center p-2 ${bt.currentBetStatusId === 2 ? "bg-green-500 text-white" : bt.currentBetStatusId === 3 ?
                    'bg-red-500 text-white' : ""}`} key={bts.betStatusId}>
                    {bts.betStatusName}
                  </div>
                ))}

            </div>
            {openBetNumbers.includes(bt.betId) && showLegs[bt.betId] && (
              <div className=" border-b-2 p-2">
                <div className="4/5">
                  {bt.leg.map((leg, index) => (
                    <div key={leg.legNo} className={`flex ${index < bt.leg.length - 1 ? 'border-b-2' : ''}`}>
                      <div className="w-1/4 text-center">
                        <div>
                          Leg {leg.legNo}
                        </div>
                        <div className="lg:text-sm text-[9px]">
                          {leg.currentOdd !== null ? leg.currentOdd?.toFixed(2) : leg.odd.toFixed(2)}
                        </div>
                      </div>
                      <div className="w-3/4 px-1">
                        {leg.eventSummary?.split(',')[2]}
                        <p>{leg.eventSummary?.split(',')[1]}-{leg.eventSummary?.split(',')[3]}</p>
                        <p>{leg.marketSummary?.split(',')[0]}-{leg.marketSummary?.split(',')[1]}</p>
                        <p>Leg Status: {betStatusesList && betStatusesList.filter((bts) => bts.betStatusId ===
                          (leg.currentBetStatusId === null ? leg.betStatusId : leg.currentBetStatusId)).map((bts) => (
                            <span className={`lg:w-1/6 w-1/5 text-center p-2 ${leg.currentBetStatusId === 2 ? " text-green-500" : leg.currentBetStatusId === 3 ?
                              ' text-red-500' : ""}`} key={bts.betStatusId}>
                              {bts.betStatusName}
                            </span>
                          ))}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {openBetNumbers.includes(bt.betId) && showLegs[bt.betId] && bt.currentBetStatusId === 2 && (
              <div className="w-full bg-green-500 p-2 text-center text-white ">
                <p>Won {bt.currentPayout?.toFixed(2)}</p>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default MyBetsComponent;
