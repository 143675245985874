import React, { useState, useContext, useEffect } from "react";
import { Tournament, Event } from "../types/Types";
import { fetchEvent, fetchSession} from '../services/PunterService';
import { AppContext } from "../services/ContextProvider";
import HorseRacingViewerComponent from "../components/HorseRacing/HorseRacingViewerComponent";
import MainBetViewerComponent from "../components/HorseRacing/MainBetViewerComponent"; 

const EventViewerComponent = () => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [viewState, setViewState] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _eventList = await fetchEvent(1, appStateStore.currentTournament?.tournamentId||0);

        setAppStateStore((appStateStore) => ({
          ...appStateStore,          
          currentEventList: _eventList,
        }));
      } catch (error) {
        // Handle error if needed
      }
    };
    
    fetchData();
  },  [appStateStore.currentTournament]);

  const handleEventClick = async (evt: Event, tourn:Tournament) => {   
    try { 
      setAppStateStore((appStateStore) => ({
        ...appStateStore,          
        currentEvent: evt, currentTournament: tourn
      })); 
    } catch (error) { 
    }
  };

  const handleTournamentClick = async (tourn: Tournament) => {   
    try { 
      setAppStateStore((appStateStore) => ({
        ...appStateStore,          
        currentTournament: tourn,
      })); 
      
    } catch (error) { 
    }
  };

  const handleNavigation = async (viewNumber: number) => {   
    try { 
      setViewState(viewNumber);
    } catch (error) { 
    }
  };

  return (
    <div className="bg-gray-50">
      {viewState === 0 && <HorseRacingViewerComponent handleEventClick={handleEventClick}  handleTournamentClick={handleTournamentClick}  handleNavigation={handleNavigation}/> }      
      {viewState === 1 && <MainBetViewerComponent handleNavigation={handleNavigation} handleEventClick={handleEventClick}/> } 
    </div>
  );
};

export default EventViewerComponent;
