import React, { useState, useContext } from 'react';
import angleDownWhite from "../../Icons/angleDownWhite.svg";
import { AppContext } from "../../services/ContextProvider";
import { calcPayout } from '../../services/CombinationCalculationService';

interface MultiBetGridProps {
  handleStakeChange: (combinationNum: number, stake: number) => void;
}

const MultiBetGridComponent: React.FC<MultiBetGridProps> = ({
  handleStakeChange
}) => {
  const { appStateStore} = useContext(AppContext);
  const [showMoreMultiBetOptions, setShowMoreMultiBetOptions] = useState<boolean>(false);

  const handleShowMoreMultiBetOptions = () => {
    setShowMoreMultiBetOptions(!showMoreMultiBetOptions);
  };
const amounts = [5, 10, 50, 100];
  const handleButtonClick = (amount: number) => {
    // Find the first item with combinations === 0 and update its stake
    const straightMultiples = appStateStore.multiBetItemList?.find(mb => mb.combinations === 0);
    if (straightMultiples) {
      const newStake = straightMultiples.stake + amount;
      handleStakeChange(straightMultiples.combinations, newStake);
    }
  };

  return (
    <>
    {appStateStore.multiBetItemList && appStateStore.multiBetItemList.length!==0 &&
    <>
      <div className="text-center bg-blue-500 text-white p-3">Multiple Bets</div>
      {appStateStore.multiBetItemList.map((mb, index) => (
        <div key={index}>
          {mb.combinations === 1 && (
            <div className="bg-blue-500 text-white p-3 cursor-pointer" onClick={handleShowMoreMultiBetOptions}>
              <div className="w-full flex">
                <div className="w-[90%]">More Multiple Options</div>
                <div className="w-[10%]">
                  <button>
                    {/* Assuming this button toggles visibility of more options */}
                    <img
                      src={angleDownWhite}
                      className={`w-5 h-5 transition-transform ms-3 ${showMoreMultiBetOptions ? '' : '-rotate-90'}`}
                      alt="arrow"
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className={`${mb.combinations>0 && !showMoreMultiBetOptions?'hidden':''}`}>
          <div className="text-bold font-bold p-2">
            {mb.combinations===0 && <div className='w-full'><p className=''>Final Odds: {mb.totalOdds?.toFixed(2)}</p></div>}
            <p>
              {mb.name} ({mb.betCount?.toString()}X)
            </p>
          </div>
          <div className='px-5'>
            <div className="w-full border border-black rounded-lg flex items-center overflow-hidden" key={mb.combinations}>
              <div className="text-lg absolute pl-2 text-gray-700">R</div>
              <input
                type="text"
                placeholder="Stake"
                className="w-full p-2 pl-8"
                value={mb.stake===0?'':mb.stake}
                onChange={(e) => {
                  let newStake = parseFloat(e.target.value);
                  handleStakeChange(mb.combinations,  newStake);
                }}
              />
            </div>
          </div>
          {mb.combinations === 0 && (
            <div className="w-full px-6 py-2">
              <div className="flex grid grid-cols-4 divide-x pt-3">
                {amounts.map((amount) => (
                  <button key={amount} className="w-3/4 h-10 rounded-lg font-bold bg-blue-100 shadow-xl" onClick={() => handleButtonClick(amount)}>
                    +{amount}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className={`w-full flex px-2 ${mb.combinations>0?'border-b-4':''}`}> 
            <div className="w-1/2">Potential PayOut:</div>
            <div className="w-1/2 flex">
              <div className='w-1/3'></div>
              <input
                type="text"
                readOnly
                value={calcPayout(mb.stake, mb.betCount, mb.totalOdds)?.toFixed(2)}
                className="font-bold pl-1 w-full"
              />
            </div>
          </div>
        </div>
        </div>
      ))}
      </>
      }
    </>
  );
};

export default MultiBetGridComponent;
