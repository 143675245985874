import React, { useContext, useEffect, useState } from "react";
import { Market, HorseHeaders, LegItem } from "../../types/Types";
import { AppContext } from "../../services/ContextProvider";
import arrowDownList from "../../Icons/arrowDownList.svg";
import { generateOpenHeaderGrid } from "../../services/OpenBetServiceHeader";
import { generateOpenBettingGrid } from "../../services/OpenBetService";
import SharedMarketListComponent from "./SharedMarketListComponent";
import BetTypeSpecificComponent from "./MainMenu/BetTypeSpecificDropDownComponent";

const OpenBetViewerComponent = () => {

  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [selectedBetTypeSpecific, setSelectedBetTypeSpecific] = useState<number>(1);
  const [headers, setHeaders] = useState<HorseHeaders[]>([]);
  const [marketList, setMarketList] = useState<Market[]>([]);
  const [headerList, setheaderList] = useState<HorseHeaders[]>([]);
  const [combinations, setCombinations] = useState<number>(0); // New state to hold the number of combinations


  let column1Selections: any[] = [];
  let column2Selections: any[] = [];
  let column3Selections: any[] = [];
  let column4Selections: any[] = [];

  useEffect(() => {
    // Update currentHorseHeadersList in appStateStore when selectedEventBetTypeId or currentEventBetType.betTypeSpecificId changes

    const initialHeadersData: HorseHeaders[] = [
      {
        headerColumns: [],
      },
    ];
    setHeaders(initialHeadersData);

    var openHeaderItem = generateOpenHeaderGrid(
      headers,
      appStateStore.currentEventBetType?.betTypeId,
      appStateStore.currentEventBetType?.betTypeSpecificId
    );

    if (appStateStore.currentMarketList) {
      var marketItems = generateOpenBettingGrid(appStateStore.currentMarketList,
        appStateStore.currentEventBetType?.betTypeId,
        appStateStore.currentEventBetType?.betTypeSpecificId);

      setAppStateStore((prevState: any) => ({
        ...prevState,
        currentHorseHeadersList: openHeaderItem,
        currentHorseHeaders: { headerColumns: headers },
        currentMarketList: marketItems
      }));
      setMarketList(marketItems);
      setheaderList(openHeaderItem)
      setCombinations(0)
    }
  }, [appStateStore.currentEventBetType?.betTypeId, appStateStore.currentBetTypeSpecific?.betTypeSpecificId, appStateStore.currentEventBetType?.betTypeSpecificId]);

  const handleClearCombination = () => {
    setCombinations(0)
    marketList.forEach((ex) => {
      ex.columns?.forEach((col) => {
        col.isSelected = false;
      });
    })
  }

  const generateCombination = (columnNumber: number, marketNumber: number) => {
    var marketItems = [...marketList];


    //toggle selection
    marketItems.forEach((mk) => {
      if (mk.marketNumber === marketNumber) {
        mk.columns?.forEach((col) => {
          if (col.columnNumber === columnNumber || appStateStore.currentBetTypeSpecific?.betTypeSpecificId === 2) {
            col.isSelected = !col.isSelected;
          }
          if (col.isSelected) {
            addToColumnSelection(col.columnNumber, mk.marketNumber, appStateStore.currentBetTypeSpecific?.betTypeSpecificId === 3);
          }
        });
      }
      else {
        mk.columns?.forEach((col, index) => {
          if (col.isSelected) {
            addToColumnSelection(col.columnNumber, mk.marketNumber, appStateStore.currentBetTypeSpecific?.betTypeSpecificId === 3);
          }
        });
      }
    });

    setAppStateStore((prevState: any) => ({
      ...prevState,
      currentMarketList: marketItems
    }));

    calculateCombinations(marketItems[0].columns?.length);
  }

  const addToColumnSelection = (columnNumber: number, marketNumber: number, addToAllColumns: boolean) => {
    if (addToAllColumns) {
      column4Selections.push(marketNumber);
      column3Selections.push(marketNumber);
      column2Selections.push(marketNumber);
      column1Selections.push(marketNumber);
    }
    else {
      if (columnNumber === 4) {
        column4Selections.push(marketNumber);
      } else if (columnNumber === 3) {
        column3Selections.push(marketNumber);
      } else if (columnNumber === 2) {
        column2Selections.push(marketNumber);
      }
      else if (columnNumber === 1) {
        column1Selections.push(marketNumber);
      }
    }
  }

  const calculateCombinations = (columnCount?: number) => {
    let combinationCount: number = 0;
    let allCombinationsArray: any[][] = [];
    if (columnCount) {
      for (var colNum = 1; colNum < columnCount; colNum++) {
        if (colNum === 4) {
          allCombinationsArray = GenerateCombinationListFromLegRunners(allCombinationsArray, column4Selections);
        } else if (colNum === 3) {
          allCombinationsArray = GenerateCombinationListFromLegRunners(allCombinationsArray, column3Selections);
        } else if (colNum === 2) {
          allCombinationsArray = GenerateCombinationListFromLegRunners(allCombinationsArray, column2Selections);
        }
        else if (colNum === 1) {
          allCombinationsArray = GenerateCombinationListFromLegRunners(allCombinationsArray, column1Selections);
        }
      }

      if (appStateStore.currentBetTypeSpecific?.betTypeSpecificId === 3) {
        allCombinationsArray = removeNoneFloatingBanker(allCombinationsArray, [2, 3]);
      }

      combinationCount = allCombinationsArray.length;


      //less than required runners are always zero combinations
      if (allCombinationsArray[0] && allCombinationsArray[0].length < (columnCount - 1)) {
        combinationCount = 0;
      }

      setCombinations(combinationCount);
    }

  }

  const GenerateCombinationListFromLegRunners = (combinationOutput: any[][], inputList: any[]): any[][] => {
    let returnList: any[][] = [];
    inputList.forEach((inputItem) => {
      if (combinationOutput.length === 0) {
        returnList.push([inputItem]);
      }
      else {
        combinationOutput.forEach((comboChildList) => {
          var currentList = [];
          comboChildList.forEach((comboChild) => {
            currentList.push(comboChild);
          })
          currentList.push(inputItem);
          returnList.push(currentList);
        });
      }
    });
    return removeDuplicates(returnList);
  }

  function removeDuplicates(arrays: number[][]): number[][] {
    // Helper function to count occurrences of numbers in an array
    function countOccurrences(array: number[]): Record<number, number> {
      let counts: Record<number, number> = {};
      array.forEach(num => {
        counts[num] = (counts[num] || 0) + 1;
      });
      return counts;
    }
    // Check if any number appears more than once in an array
    function hasDuplicates(array: number[]): boolean {
      const counts = countOccurrences(array);
      return Object.values(counts).some(count => count > 1);
    }

    // Filter out arrays with duplicates
    return arrays.filter(array => !hasDuplicates(array));
  }

  function removeNoneFloatingBanker(arrays: number[][], floaters: number[]): number[][] {
    function hasAllFloaters(array1: number[]): boolean {
      return floaters.every(num => array1.includes(num));
    }
    return arrays.filter(array => hasAllFloaters(array));
  }

  const addMarketToBetSlip = async () => {
    //leg array
    var legsToSend: LegItem[] = [];
    var marketItem = [...marketList];

    marketItem.forEach((mkt, index) => {
      //create leg item
      var legToAdd: LegItem = {
        legNo: index + 1,
        eventId: mkt.eventId,
        marketNumber: mkt.marketNumber,
        betTypeId: mkt.betTypeId,
        betTypeSpecificId: appStateStore.currentBetTypeSpecific?.betTypeSpecificId,
        specifiers: mkt.specifiers,
        dataProviderId: mkt.dataProviderId || 1,
        odd: mkt.currentOdd,
        stake: appStateStore.currentBetSlipItem?.stake || 0,
        payout: appStateStore.currentBetSlipItem?.stake || 0,
        sportId: 2,
        legSelection: []
      }
      if (mkt.marketClicked) {
        // add to existing legselection
        legToAdd.legSelection.push({
          legNo: mkt.eventId,
          selectionNo: mkt.marketNumber,
          groupId: mkt.marketNumber
        });
      }

      legsToSend.push(legToAdd);
    });

    setAppStateStore((prevState: any) => ({
      ...prevState,
      currentMarketList: marketItem,
      currentBetSlipItem: {
        betTypeId: appStateStore.currentEventBetType?.betTypeId,
        betTypeName: appStateStore.currentEventBetType?.betTypeName,
        categoryName: prevState.currentCategory?.categoryName,
        tournamentName: prevState.currentTournament?.tournamentName,
        eventName: prevState.currentEvent?.eventName,
        eventId: appStateStore.currentEventBetType?.eventId,
        eventDate: prevState.currentEvent?.eventDate,
        stake: prevState.currentBetSlipItem?.stake,
        payout: prevState.currentBetSlipItem?.payout,
        dataProviderId: appStateStore.currentEventBetType?.dataProviderId,
        combinations: combinations,
        accountId: 1,
        tenantId: 1,
        leg: legsToSend
      }
    }));
  };

  const getButtonWidth = (betTypeId: number, columnName: string) => {
    return betTypeId === 1 &&
      (columnName === '1st' || columnName === '2nd' || columnName === '3rd' ||
        columnName === '4th' || columnName === 'Win' || columnName === 'Place')
      ? 'lg:w-20 w-16' : 'lg:w-60 w-40';
  };

  return (
    <div className="font-inter">
      <div className={`w-full lg:h-1/2 lg:2/3 lg:max-h-[82vh] max-h-[80vh] pb-10 lg:pb-0 md:pb-5 pb-2 overflow-y-auto`}>

        <table className="table-auto text-center lg:p-10 p-2 w-full">
          <thead>
            <tr className="bg-gray-200 lg:p-5 px-1 text-xs lg:text-lg">
              <th>
                <div className="lg:px-2 flex">
                  <img src={arrowDownList} className="hidden md:inline ml-2" alt="arrow down list" />

                  {appStateStore.currentEventBetType?.betTypeId !== 13 &&
                    <BetTypeSpecificComponent onSelect={setSelectedBetTypeSpecific} />
                  }
                </div>
              </th>
              {headerList && headerList.map((hd) => (
                hd.headerColumns && hd.headerColumns.map((cl) => (
                  <th key={cl.headerNumber} className="text-[11px] lg:text-base py-5 lg:pl-10 sm:pr-2">
                    {cl.headerName}
                  </th>
                ))
              ))}
            </tr>
          </thead>
          <tbody className="border-b-5 ">
            {marketList &&
              marketList.map((mkt) => (

                <tr
                  className="border-b-2"
                  key={`${mkt.marketNumber}-${mkt.eventId}`} >
                  <SharedMarketListComponent currentMarket={mkt} />

                  {mkt.columns && mkt.columns.map((cl, index) => (
                    <td className={`text-[11px] lg:text-base
                  ${getButtonWidth(mkt.betTypeId, cl.columnName) === 'lg:w-60 w-40' ? '' : ''}`} key={index}>
                      {['Opening', 'Price', 'Previous'].includes(cl.columnName) ? (
                        cl.columnOdd
                      ) : (
                        
                        <button
                          className={`lg:h-full lg:p-5 my-1 rounded-lg font-bold h-14 
                       ${getButtonWidth(mkt.betTypeId, cl.columnName)}
                       ${cl.isSelected ? 'bg-blue-500 text-white lg:text-base text-[13px]' : 'bg-blue-100'} `}
                          onClick={() => generateCombination(cl.columnNumber, mkt.marketNumber)}
                          key={mkt.marketNumber}>
                          {cl.columnOdd}
                        </button>
                      )}
                    </td>
                  ))}

                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      {appStateStore.currentEventBetType?.betTypeId !== 13 && (
        <div className="flex justify-end lg:pr-10 lg:pt-2 pt-1 ">

          <div className="text-center">
            <button className="bg-blue-500 lg:p-2 p-1 lg:text-base text-sm text-white rounded" disabled={combinations < 1} onClick={() => handleClearCombination()}>
              Clear Selections
            </button>
          </div>
          <div className="text-center lg:pl-2 px-2">
            <button className="bg-green-500 lg:p-2 p-1 lg:text-base text-sm text-white rounded" onClick={() => addMarketToBetSlip()} disabled={combinations < 1}>
              Add to Betslip ({combinations} {combinations > 1 ? "combinations" : "combination"})
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenBetViewerComponent; 
