import React, { useContext, useEffect, useState } from "react";
import { HorseHeaders, EventLeg, LegItem} from "../../types/Types";
import { AppContext } from "../../services/ContextProvider";
import { generateExoticHeaderGrid } from "../../services/ExoticBetServiceHeader";
import { fetchEventLeg } from "../../services/PunterService";

interface ExoticsBetViewerProps {
  selectedEventBetTypeId: number;
  eventGroupId?: number;
}

const ExoticBetViewerComponent: React.FC<ExoticsBetViewerProps> = ({ eventGroupId }) => {
  const { appStateStore, setAppStateStore } = useContext(AppContext)
  const [headers, setHeaders] = useState<HorseHeaders[]>([]);
  const [exotics, setExotics] = useState<EventLeg[]>([]);
  const [headerList, setheaderList] = useState<HorseHeaders[]>([]);
  const [combinations, setCombinations] = useState<number>(0);

  useEffect(() => {
   
      const initialHeadersData: HorseHeaders[] = [
        {
          headerColumns: [],
        },
      ];
      setHeaders(initialHeadersData);

      if (appStateStore.currentEventBetType && appStateStore.currentEventBetType?.betTypeId > 5 && appStateStore.currentEventBetType?.betTypeId < 13) {

      var openHeaderItem = generateExoticHeaderGrid(
        headers,
        appStateStore.currentEventBetType?.betTypeId,
        appStateStore.currentEventBetType?.betTypeSpecificId
      );
      setAppStateStore((prevState: any) => ({
        ...prevState,
        currentHorseHeadersList: openHeaderItem,
      }));
      setheaderList(openHeaderItem)

      const fetchData = async () => {
        const ExoticBetData: EventLeg[] = await fetchEventLeg(eventGroupId);
        setExotics(ExoticBetData)
      };
      fetchData();

    }
  }, [appStateStore.currentEventBetType?.betTypeSpecificId, appStateStore.currentEventBetType?.betTypeId]);


  const handleClearCombination = () => {
    setCombinations(0)
    exotics.forEach((ex) => {
      ex.markets?.forEach((mkt) => {
        mkt.marketClicked = false;
      });
    })
  }

  const generateCombination = (legNumber?: number, marketNumber?: number) => {
    var exoticItem = [...exotics];
    var legCount = 0;
    var totalCombinations = 1;
    //toggle selection
    exoticItem.forEach((ex) => {
      if (ex.legNumber === legNumber) {
        ex.markets?.forEach((mkt) => {
          if (mkt.marketNumber === marketNumber) {
            mkt.marketClicked = !mkt.marketClicked;
          }
        })
      }
    });

    exoticItem.forEach((ex) => {
      ex.markets?.forEach((mkt) => {
        if (mkt.marketClicked) {
          legCount++;
        }
      })
      totalCombinations = totalCombinations * legCount;
      legCount = 0;
    });

    setCombinations(totalCombinations);

    setAppStateStore((prevState: any) => ({
      ...prevState,
      currentEventLeg: exoticItem
    }));
  }

  const addMarketToBetSlip = async () => {

    //leg array
    var legsToSend: LegItem[] = [];
    var exoticItem = [...exotics];

    exoticItem.forEach((ex) => {
      //create leg item
      var legToAdd: LegItem = {
        legNo: ex.legNumber,
        eventId: ex.eventId,
        marketNumber: ex.marketNumber,
        betTypeId: ex.betTypeId,
        specifiers: ex.specifiers,
        dataProviderId: ex.dataProviderId || 1,
        odd: ex.currentOdd,
        stake: appStateStore.currentBetSlipItem?.stake || 0,
        payout: appStateStore.currentBetSlipItem?.stake || 0,
        sportId: 2,
        legSelection: []
      }
      ex.markets?.forEach((mkt) => {
        if (mkt.marketClicked) {
          // add to existing legselection
          legToAdd.legSelection.push({
            legNo: ex.legNumber,
            selectionNo: mkt.marketNumber,
            groupId: mkt.marketNumber
          });
        }
      });

      legsToSend.push(legToAdd);
    });

    setAppStateStore((prevState: any) => ({
      ...prevState,
      currentMarketList: exotics,
      currentBetSlipItem: {
        betTypeId: appStateStore.currentEventBetType?.betTypeId,
        betTypeName: appStateStore.currentEventBetType?.betTypeName,
        categoryName: prevState.currentCategory?.categoryName,
        tournamentName: prevState.currentTournament?.tournamentName,
        eventName: prevState.currentEvent?.eventName,
        eventId: appStateStore.currentEventBetType?.eventId,
        eventDate: prevState.currentEvent?.eventDate,
        stake: prevState.currentBetSlipItem?.stake,
        payout: prevState.currentBetSlipItem?.payout,
        dataProviderId: appStateStore.currentEventBetType?.dataProviderId,
        combinations: combinations,
        accountId: 1,
        tenantId: 1,
        leg: legsToSend
      }
    }));
  };


  return (
    <div className="table-auto text-center items-center justify-center w-full">
      <div className="w-full lg:h-1/2 lg:2/3  max-h-[70vh] lg:max-h-[77vh]  pb-10 lg:pb-0 md:pb-5 pb-2 overflow-y-auto">
        <div className="bg-gray-200 px-1 text-xs lg:text-lg ">
          <div className="flex justify-around">
            {headerList &&
              headerList.map((hd) =>
                hd.headerColumns &&
                hd.headerColumns.map((cl) => (
                  <div key={cl.headerNumber} className="p-2">
                    {cl.headerName}
                  </div>
                ))
              )}
          </div>
        </div>

        <div className="border-b-5 flex">
          {exotics &&
            exotics.map((exoticItem) => (
              <div key={exoticItem.legNumber} className={`border-b-2 w-2/3`}>
                <div>
                  {exoticItem.markets &&
                    exoticItem.markets.map((mkt) => (
                      <div key={mkt.marketNumber} className="w-full lg:h-36 lg:p-2 p-1 border-b-2 ">

                        {/* accommodates different screen sizes */}
                        <button
                          className={`lg:h-full lg:p-2 p-1 rounded-lg bg-blue-100 items-center ${mkt.marketClicked ? 'bg-blue-500 text-white' : ''
                            } 
                         ${appStateStore.currentEventBetType?.eventGroupId === 3 ? 'w-full' : ''} 
                         ${appStateStore.currentEventBetType?.eventGroupId === 4 ? 'w-full h-28' : ''}
                         ${appStateStore.currentEventBetType?.eventGroupId === 5 ? 'lg:w-60 w-20' : ''}
                         ${appStateStore.currentEventBetType?.eventGroupId === 6 ? 'lg:w-full w-13 h-20' : ''}
                         ${appStateStore.currentEventBetType?.eventGroupId === 7 ? 'lg:w-full w-10 h-20' : ''}
                         ${appStateStore.currentEventBetType?.eventGroupId === 8 ? 'lg:w-full w-13 h-20' : ''}
                         `}
                          onClick={() => generateCombination(exoticItem.legNumber, mkt.marketNumber)} >

                          <p className={`font-bold
                          ${appStateStore.currentEventBetType?.eventGroupId === 3 ? 'lg:text-2xl text-xl' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 4 ? 'lg:text-2xl text-xl' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 5 ? 'lg:text-2xl text-xl' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 6 ? 'lg:text-2xl text-lg' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 7 ? 'lg:text-2xl text-[15px]' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 8 ? 'lg:text-2xl text-lg' : ''}
                          `}>
                            {mkt.marketNumber}
                          </p>

                          <p className={`text-blue-500 font-bold 
                          ${appStateStore.currentEventBetType?.eventGroupId === 3 ? 'lg:text-xl text-sm' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 4 ? 'lg:text-xl text-sm' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 5 ? 'lg:text-xl text-xs' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 6 ? 'lg:text-xl text-[7px]' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 7 ? 'lg:text-xl text-[7px]' : ''}
                          ${appStateStore.currentEventBetType?.eventGroupId === 8 ? 'lg:text-xl text-[7px]' : ''}

                          ${mkt.marketClicked ? 'bg-blue-500 text-white' : ''}
                          `}>
                            {mkt.marketName.toUpperCase()}
                          </p>

                          <p className="lg:text-lg">{mkt.currentOdd}</p>
                        </button>

                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-end lg:pr-10 lg:pt-2 py-1">
        <div className="text-center">
          <button className="bg-blue-500 lg:p-2 p-1 lg:text-base text-sm  text-white rounded " onClick={() => handleClearCombination()}>
            Clear Selections
          </button>
        </div>
        <div className="text-center lg:pl-2 px-2">
          <button className="bg-green-500 lg:p-2 p-1 lg:text-base text-sm rounded text-white" onClick={() => addMarketToBetSlip()} disabled={combinations < 1}>
            Add to Betslip ({combinations} {combinations > 1 ? "combinations" : "combination"})
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExoticBetViewerComponent;
