import React, { useState, useEffect, useContext } from 'react';
import { fetchBetTypeSpecific} from '../../../services/PunterService';
import { BetTypeSpecific} from '../../../types/Types';
import angleDown from '../../../Icons/angleDown.svg'
import { AppContext } from '../../../services/ContextProvider';

interface BetTypeSpecificComponentProps {
  onSelect: (selectedBetType: number) => void;
}

const BetTypeSpecificComponent: React.FC<BetTypeSpecificComponentProps> = ({ onSelect }) => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [betTypeSpecific, setBetTypeSpecific] = useState<BetTypeSpecific[]>([]);

  useEffect(() => {
    if(appStateStore.currentEventBetType && appStateStore.currentEventBetType?.betTypeId > 1 && appStateStore.currentEventBetType?.betTypeId < 6)
    {
    const fetchData = async () => {
        const response = await fetchBetTypeSpecific(appStateStore.currentEventBetType?.betTypeId||5);
        if (response) {
          setBetTypeSpecific(response);
        }   
    };
    fetchData();
  }
  }, [appStateStore.currentEventBetType?.betTypeId]); // Trigger fetch when betTypeId changes

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: number) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
   
  };

   const handleBetTypeClick = async (betTypeSpecific: BetTypeSpecific) => {
     if (betTypeSpecific) {
       const _betTypeSpecific = await fetchBetTypeSpecific(appStateStore.currentEventBetType?appStateStore.currentEventBetType.betTypeId:5)
       setAppStateStore((appStateStore) => ({
         ...appStateStore,
         currentBetTypeSpecific: betTypeSpecific,
         currentBetTypeSpecificList: _betTypeSpecific,
       }));
     }
   };

  return (
    <div className="text-left pl-2 ">
      <div
        className="flex items-center justify-between  lg:w-40  px-4 py-2 bg-white border border-gray-400
                   rounded-lg cursor-pointer lg:text-sm text-xs "
        onClick={toggleDropdown}>

        <span>
        {
          betTypeSpecific.find((bts)=>{return bts.betTypeSpecificId ===selectedOption})?.betTypeSpecificName}
        </span>

        <img src={angleDown} className={`w-3 h-3 ml-2 ${isOpen ? "transform rotate-180" : ""}`} alt="angle down" />
      </div>

      {isOpen && (
        <div className="absolute z-10 lg:w-40 w-24 -mt-2 bg-white border 
                        rounded-b-md shadow-lg">
          {betTypeSpecific.length > 0 ? (
            betTypeSpecific.map((bts) => (
              <div
                key={`${bts.betTypeId}`}
                className={`px-2 py-2 cursor-pointer text-xs lg:text-sm
                ${selectedOption === bts.betTypeSpecificId ? 'bg-blue-300 font-bold text-blue-600' : 'hover:bg-gray-100'}`}
                onClick={() => { handleOptionClick(bts.betTypeSpecificId); handleBetTypeClick(bts) }}>
                {bts.betTypeSpecificName}
              </div>
            ))
          ) : (
              <p className="text-center">No EventBetType Specific available</p>
            )}
        </div>
      )}
    </div>
  );
};

export default BetTypeSpecificComponent;