import React, { useEffect, useContext, useState } from 'react';
import angleRight from "../../../Icons/angleRight.svg";
import { AppContext } from '../../../services/ContextProvider';

interface DateSelectorProps {
  selectedDate: string;
  onSelectDate: (date: string) => void;
  dates?: string[];
  expandedTournaments?: string[];
  tournamentDate?: string;
}

const DateSelector: React.FC<DateSelectorProps> = ({ selectedDate, onSelectDate, expandedTournaments, dates }) => {
  const { appStateStore } = useContext(AppContext);
  const [showAllDates, setShowAllDates] = useState(false);
  const uniqueDates = Array.from(new Set(dates)).sort();
  
  useEffect(() => {
    if(appStateStore.todaysDate){
      handleDateClick(appStateStore.todaysDate)
      onSelectDate(appStateStore.todaysDate)
    }
  }, []);

  // Declare eventDate here
  let eventDate: string;

  //Toggle showAllDates state
  const toggleShowAllDates = () => {
    setShowAllDates(!showAllDates);
  };
  // Handler for date click event
  const handleDateClick = (date: string) => {
      onSelectDate(date);
  };

  return (
    <div className="flex lg:space-x-5 font-light justify-end text-right">

      <div className="dates-container w-full" style={{ overflowX: showAllDates ? 'auto' : 'hidden', whiteSpace: 'nowrap' }}>
        {/* Iterates over the first three dates */}
        {uniqueDates.slice(0, 3).map((date) => (
          <div key={date}
            className={`inline-flex text-left cursor-pointer lg:text-base text-xs 
               ${selectedDate === date ? 'font-bold border-b-2 border-blue-500' : ''}`}
            onClick={() => handleDateClick(date)}
          >
            <div className='lg:px-4 px-2'> {date} </div>

            {expandedTournaments && expandedTournaments.map((event) => (
              eventDate = event.split(' ')[0], // Assigning eventDate here
              eventDate === date ? (
                <div key={event} className="active:text-blue-500">
                  {event}
                </div >
              ) : null
            ))}
          </div>
        ))}
      </div>

      {/* Button rendered conditionally based on showAllDates */}
      {!showAllDates && (uniqueDates.length > 1 && window.innerWidth < 1024) && (
        <div className={`w-1/6 lg:w-20 pt-1 lg:hidden text-right`}>
          <button onClick={toggleShowAllDates}>
            <img src={angleRight} className="h-[13px] w-[12vh] cursor-pointer lg:hidden" alt="angle right" />
          </button>
        </div>
      )}
    </div>

  );
};

export default DateSelector;