import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BetSlipComponent from '../components/BetSlipComponent';
import CategoryBrowserComponent from '../components/CategoryBrowserComponent';
import EventViewerComponent from '../components/EventViewerComponent';
import { Tournament, Event, Balance } from '../types/Types';
import { useMediaQuery } from 'react-responsive';
import { AppContext } from "../services/ContextProvider";
import hamburgerMenu from "../Icons/hamburgerMenu.svg"
import '@fontsource/inter';
import MainBetViewerComponent from "../components/HorseRacing/MainBetViewerComponent";
import MyBetsComponent from "../components/HorseRacing/MyBetsComponent";
import betSlipArrow from "../Icons/betSlipArrow.svg"
import { fetchSession, fetchBalance } from '../services/PunterService';
import TokenNotAvailableComponent from "../components/HorseRacing/TokenNotAvailableComponent";

interface HorseRacingPageProps { }

export let token:string|null;

const HorseRacingPage: React.FC<HorseRacingPageProps> = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isBetSlipOpened, setBetSlipOpened] = useState(false);
  const [viewState, setViewState] = useState<number>(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const operatorKey = queryParams.get('operatorkey');
      const playerUniqueId = queryParams.get('playeruniqueid');
      const _session = await fetchSession(String(operatorKey), String(playerUniqueId));
      const _currentBalance = await fetchBalance(String(playerUniqueId));

      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentSession: _session,
        currentBalance: _currentBalance
      }));
    }

    fetchData();
  }, []);

  token = appStateStore.currentSession? appStateStore.currentSession?.jwtSessionToken:null;

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!isBurgerMenuOpen);
    // Close BetSlip when opening BurgerMenu
    setBetSlipOpened(false);
  };

  const toggleBetSlip = () => {
    setBetSlipOpened(!isBetSlipOpened);
    // Close BurgerMenu when opening BetSlip
    setBurgerMenuOpen(false);
  };

  const handleNavigation = async (viewNumber: number) => {
    try {
      setViewState(viewNumber);
    } catch (error) {
    }
  };

  const handleEventClick = async (evt: Event) => {
    try {
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentEvent: evt,
      }));
    } catch (error) {
    }
  };

  const handleTournamentClick = async (tourn: Tournament) => {
    try {
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentTournament: tourn,
      }));
    } catch (error) {
    }
  };

  return (
    <>
      <div className={`lg:w-1/6  ${isSmallScreen ? 'sm:w-full absolute top-0 left-0 z-20' : ''} ${isBurgerMenuOpen ? '' : ''}`}>
        {isSmallScreen ? (
          <div className="relative grid left-0">
            <img src={hamburgerMenu} alt="hamburger menu" className="h-10 px-2 pt-2" onClick={toggleBurgerMenu}
              style={{ zIndex: 30 }} />  {/* Ensure the hamburger menu is on top */}

            {isBurgerMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <CategoryBrowserComponent handleEventClick={handleEventClick} handleNavigation={handleNavigation} handleTournamentClick={handleTournamentClick} />
              </div>
            )}
          </div>
        ) : (
          <CategoryBrowserComponent handleEventClick={handleEventClick} handleNavigation={handleNavigation} handleTournamentClick={handleTournamentClick} />
        )}
      </div>

      <div className={`sm:w-full lg:w-4/6 ${isSmallScreen ? 'sm:absolute sm:top-0 sm:left-0 sm:z-1 ' : ''}`}>
        {viewState === 1 ? <MainBetViewerComponent handleNavigation={handleNavigation} handleEventClick={handleEventClick} /> : <EventViewerComponent />}

      </div>

      <div className="w-1/6 w-1/6 lg:h-screen">
        {isSmallScreen && (
          <div className={`lg:w-1/6  ${isBetSlipOpened ? 'fixed -bottom-0 -h-screen  left-0 right-0' : 'fixed -h-screen  -bottom-0 left-0 right-0 '}`} >
            <div className="flex flex-col h-full justify-end items-center">
              <button onClick={toggleBetSlip} className="bg-blue-500 rounded-t-full flex-grow -mb-4 overlay bg-blue-500 rounded-t-full flex-grow -mb-4 relative top-0 left-0 z-50y">
                <img src={betSlipArrow} className="w-10 h-6 text-white" alt="betSlipArrow" />
              </button>
              <BetSlipComponent isBurgerMenuOpen={isBetSlipOpened} />
            </div>
          </div>
        )}
        {!isSmallScreen && (
          <BetSlipComponent isBurgerMenuOpen={!isBetSlipOpened} />
        )}
      </div>

      {appStateStore.isPopUpMyBets && appStateStore.currentSession?.jwtSessionToken &&
        <>
          <div className="fixed inset-0 bg-gray-800 opacity-75"></div>
          <div className="absolute top-15 lg:right-5 lg:left-5 right-2 left-2 flex items-center justify-center bg-white p-8 rounded-lg shadow-lg z-50">
            <MyBetsComponent />
          </div>
        </>
      }

      {appStateStore.isCurrentSessionTokenAvailable === true && appStateStore.currentSession?.jwtSessionToken === null &&
        <>
          <div className="fixed inset-0 bg-gray-800 opacity-75"></div>
          <div className="absolute top-15 lg:right-5 lg:left-5 right-2 left-2 flex items-center justify-center bg-white p-8 rounded-lg shadow-lg z-50">
            <TokenNotAvailableComponent />
          </div>
        </>

      }
    </>
  );
};
export default HorseRacingPage;
