import { HorseHeaders } from "../types/Types";
import ToggleComponent from "../components/HorseRacing/MainMenu/ToggleComponent";

export const generateExoticHeaderGrid = (headers: HorseHeaders[], betTypeId?: number, betTypeSpecificId?: number): HorseHeaders[] => {
    //Double
    if (betTypeId === 6) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,
            }]
        });
    }
    //Pick 3
    else if (betTypeId === 7) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 3</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 2,
            }]
        });

    }
    //Jackpot
    else if (betTypeId === 8) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 3</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 2,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 4</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 3,
            }]
        });

    }
    //Pick 6
    else if (betTypeId === 9) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,

            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 3</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 2,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 4</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 3,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 5</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 4,

            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 6</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 5,
            }]
        });

    }
    //Bipot
    else if (betTypeId === 11) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,

            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 3</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 2,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 4</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 3,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 5</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 4,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 6</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 5,
            }]
        });

    }
    //Place Accumulator
    else if (betTypeId === 10) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 1</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 0,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 2</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 1,

            },
            {
                
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 3</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 2,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 4</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 3,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 5</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 4,

            },
            {
                headerName: <div className="items-center justify-center justify-center">
                    <p className="lg:text-lg text-sm font-bold pb-1">Leg 6</p>
                    <ToggleComponent inputValue="" />
                </div>,
                headerNumber: 5,
            },
            {
                headerName: <div className="items-center justify-center justify-center">
                <p className="lg:text-lg text-sm font-bold pb-1">Leg 7</p>
                <ToggleComponent inputValue="" />
            </div>,
                headerNumber: 6,
            }]
        });

    };

    return headers;

};