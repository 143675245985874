import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../services/ContextProvider";
import BetInputComponent from "./Input/BetInputComponent";
import { BetSlipItem, Market, LegItem, MultiBetSlipItem } from "../types/Types";
import { placeBet, multiPlaceBet,fetchBalance, updateBalance } from '../services/PunterService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from 'react-responsive';
import slip from "../Icons/slip.svg";
import binWhite from "../Icons/binWhite.svg";
import binBlack from "../Icons/binBlack.svg";
import xmark from "../Icons/xmark.svg";
import MultiBetGridComponent from "./Input/MultiBetGridComponent";
import { generateCombinationBets } from '../services/CombinationCalculationService';

interface BetSlipProps { isBurgerMenuOpen: boolean }

const BetSlipComponent: React.FC<BetSlipProps> = ({ isBurgerMenuOpen }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });
  const [betSlipItems, setBetSlipItems] = useState<BetSlipItem[]>([]);
  const [multiBetStake, setMultiBetStake] = useState<number>(0);
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [multiBetPotentialPayout, setMultiBetPotentialPayout] = useState<number>(0);
  const [multiBetSlipItem, setMultiBetSlipItem] = useState<MultiBetSlipItem>();  
  const [multiBetSlipItems, setMultiBetSlipItems] = useState<MultiBetSlipItem[]>([]); 
  const [stakeValues, setStakeValues] = useState(new Array(betSlipItems.length).fill(0));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  useEffect(() => {
    if (appStateStore.currentBetSlipItem) {
      setBetSlipItems((prevBetSlipItems: BetSlipItem[] | undefined) => {
        const existingMarket = prevBetSlipItems?.find(
          (item) => item.marketUniqueIdentifier === appStateStore.currentBetSlipItem?.marketUniqueIdentifier);
        if (existingMarket) {
          // If the market is already in the bet slip, remove it
          return (prevBetSlipItems || []).filter(
            (item) => item.marketUniqueIdentifier !== existingMarket.marketUniqueIdentifier
          );
        } else {
          // If the market is not in the bet slip, add it
          return [
            ...(prevBetSlipItems || []), appStateStore.currentBetSlipItem as BetSlipItem];
        }
      });
    }
    handleMultiItem()
  }, [appStateStore.currentBetSlipItem]);

  
  useEffect(() => {
    const fetchData = async () => { 
      const _currentBalance = await fetchBalance(String(appStateStore.currentBalance?.punterId));
      setAppStateStore((appStateStore) => ({
        ...appStateStore, 
        currentBalance: _currentBalance
      }));
    }

    fetchData();
  }, []);

  useEffect(() => {

    if (showMultiBetInput && betSlipItems.length > 1) {
      let tempArray: MultiBetSlipItem[]= [];
      if(multiBetSlipItem){
      for (let i = 0; i < betSlipItems.length; i++) {
      
        const newMultiBetSlipItem: MultiBetSlipItem = { 
          stake: stakeValues[i]?stakeValues[i]:0, 
          combinations: i,
          accountId: multiBetSlipItem.accountId,
          tenantId:multiBetSlipItem.tenantId,
          name:'',
          leg:multiBetSlipItem.leg
        };

        generateCombinationBets(i, newMultiBetSlipItem, betSlipItems);

        if(i===0){ newMultiBetSlipItem.name = `Multiple ${(betSlipItems.length)} Legs`};
        if(i>1){ newMultiBetSlipItem.name = `${(i)} Fold`};      
        if(i===1){ newMultiBetSlipItem.name = `Super Multi`}; 
        

        tempArray.push(newMultiBetSlipItem);
        if(betSlipItems.length===2) break; 
      } 
      setMultiBetSlipItems(tempArray);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        multiBetItemList:tempArray,
        currentBetSlipItemList:betSlipItems
      }));
    }
    }
    else
    {
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        multiBetItemList:undefined,
        currentBetSlipItemList:betSlipItems
      }));
    }
  }, [betSlipItems, stakeValues]);

  const handleStakeChange = (combinationNum:number, stake:number) => {
    const updatedStakeValues = [...stakeValues];
    updatedStakeValues[combinationNum] = stake;
    setStakeValues(updatedStakeValues);
};
const showMultiBetInput = !betSlipItems.some((item, index) => {return betSlipItems.slice(index + 1).some(otherItem => 
  otherItem.eventId === item.eventId || item.betTypeId!==1);
});

  // Set marketClicked to false for all items in currentMarketList
  const handleClearBetSlip = () => {
    if (appStateStore.currentMarketList) {
      var newMarkets = [...appStateStore.currentMarketList];
      newMarkets.forEach((item) => {item.columns?.forEach((col) => {col.isSelected = false})})
      setAppStateStore((prevState: any) => ({
        ...prevState,
        currentMarketList: newMarkets
      }));
    }
    
    // Clear bet slip items
    setBetSlipItems([]);
    //Clear Multibets
    setStakeValues([])
  };

  const handlePlaceBet = async () => {
    // Filter out items with zero or undefined stake
    if(appStateStore.currentSession?.jwtSessionToken===null){
      setAppStateStore((prevState: any) => ({
        ...prevState,
        isCurrentSessionTokenAvailable: true
      }));
    }
    else{
      betSlipItems.forEach(async (item: BetSlipItem) => {
        if (item.stake > 0) {
            var PlaceBetResponse = await placeBet(item, String(appStateStore.currentSession?.jwtSessionToken));
            await updateBalance(String(appStateStore.currentBalance?.punterId), String(PlaceBetResponse.relatedId), Number(appStateStore.currentBalance?.cashBalance), item.stake)
            if (PlaceBetResponse && PlaceBetResponse.responseCode === 200) {
                toast.success(`${PlaceBetResponse.responseMessage}`);
                handleClearBetSlip();
                const _currentBalance = await fetchBalance(String(appStateStore.currentBalance?.punterId));
                setAppStateStore((appStateStore) => ({
                  ...appStateStore, 
                  currentBalance: _currentBalance
                }));
            } else {
                toast.error(`${PlaceBetResponse.responseMessage}`);
            }

           
        }
    });
      handleMultiBet()
    }
    setIsButtonDisabled(false)   
  };
  //Handle straight multipleBet
  const handleMultiBet = async () => {
    // Iterate over valid MultiBetSlipItems and place bets
    let allMultipleLegs:LegItem[] = []
    let legNo:number = 1;
    let previousStake = 0
    betSlipItems.forEach(bs => {
      let legIt:LegItem = bs.leg[0];
      legIt.legNo = legNo;
      if(previousStake!==0){
        legIt.stake = previousStake*legIt.odd;
      }
      allMultipleLegs.push(legIt);
      previousStake = legIt.payout;
      legNo++;
    });

    appStateStore.multiBetItemList?.forEach(async (item) => {
        item.leg = allMultipleLegs;
        if (item.stake > 0) {
        const responseMultiData = await multiPlaceBet(item, String(appStateStore.currentSession?.jwtSessionToken));
        if (responseMultiData.responseCode === 200) {
          toast.success(`${responseMultiData.responseMessage}`);
          handleClearBetSlip()
        } else toast.error(`${responseMultiData.responseMessage}`);
      }
    });
  };
  
  const totalOdds:number = betSlipItems.reduce((accumulator, mkt) => {
    return ((accumulator + 1) * (Number(mkt.Odd || 0) + 1));
  }, 0);

  const handleBetChange = (value: number, mkt: Market) => {
    const updatedBetSlipItems = betSlipItems.map((item) =>
      item.marketUniqueIdentifier === mkt.marketUniqueIdentifier
        ? {
          ...item,
          stake: value,
          payout:(Number(value) * (Number(mkt.Odd) || 0) + Number(value)) || 0,
          leg: item.leg.map((legItem) => ({
            ...legItem,
            stake: value,
            payout:(Number(value) * (Number(mkt.Odd) || 0) + Number(value)) || 0,
          })),
        }: item
    );
    setBetSlipItems(updatedBetSlipItems);
  };

  const handleRemoveEvent = (market: BetSlipItem) => {
    setBetSlipItems((prevBetSlipItems) =>
      prevBetSlipItems.filter((item) => !(item.marketNumber === market.marketNumber
        && item.eventId === market.eventId && item.dataProviderId === market.dataProviderId && item.currentOdd === market.currentOdd
        && item.marketUniqueIdentifier === market.marketUniqueIdentifier))
    );

    if (appStateStore.currentMarketList) {
      var newMarkets = [...appStateStore.currentMarketList];
      newMarkets.forEach((item) => {
        if (item.marketNumber === market.marketNumber && item.eventId === market.eventId) {
          item.columns?.forEach((col) => {
            if (market.marketNumber === col.columnMarketNumber && market.marketColumnName === col.columnName) 
            {
              col.isSelected = false;
            }
          });
        }
      })
      setAppStateStore((prevState: any) => ({
        ...prevState,
        currentMarketList: newMarkets
      }));
    }
  };


  const handleMultiItem = () => {
    if (showMultiBetInput) {
      var legsToSend: LegItem[] = [];
      var previousPayout = multiBetStake;
      betSlipItems.forEach((mlt, index) => {
        // Create leg item
        var legToAdd: LegItem = {
          legNo: index + 1,
          eventId: mlt.eventId,
          marketNumber: mlt.marketNumber,
          betTypeId: mlt.betTypeId,
          betTypeSpecificId: mlt.betTypeId,
          specifiers: mlt.specifiers,
          dataProviderId: mlt.dataProviderId || 1,
          odd: mlt.Odd,
          stake: index === 0 ? multiBetStake : previousPayout,
          payout: (previousPayout * mlt.Odd) + previousPayout,
          sportId: 2,
          legSelection: []
        };
        // Add leg item to the array
        legsToSend.push(legToAdd);
        // Update previous payout for the next iteration
        previousPayout = legToAdd.payout;
      });
      setMultiBetSlipItem({
        stake: multiBetStake,
        odd: totalOdds,
        payout: multiBetPotentialPayout + multiBetStake,
        combinations: 0,
        accountId: 1,
        tenantId: 1,
        leg: legsToSend,
        name: ''
      });
    }
  }


  return (
    <div className="shadow-2xl h-full w-full font-inter ">
      <div className="bg-gray-50 w-full flex flex-col items-center justify-between">
        <div className="bg-blue-500 lg:pt-3  w-full rounded-t-3xl lg:rounded-t-sm">
          <div className="lg:pb-4 pb-2 flex items-center justify-between">

            <div className="flex items-center">
              <img src={slip} className="w-5 h-5 ml-5" alt="bet slip" />
              <div className=" ml-2 text-white font-bold text-xl">Betslip</div>
            </div>

            <div className="text-white flex items-center p-2">
              {isSmallScreen && isBurgerMenuOpen ? (
                <div className="w-full flex">

                  <div>
                    <button onClick={handleClearBetSlip} className="lg:hidden">
                      <img src={binWhite} className="w-5 h-5 text-white" alt="bin" />
                    </button>
                  </div>

                  <div>
                    <span className="text-blue-500 px-[6px] mx-2 border bg-white rounded-full font-bold">
                      {betSlipItems.length}
                    </span>
                  </div>
                </div>

              ) :
                <span className="text-blue-500 ml-2 px-2 border lg:hidden bg-white rounded-full font-bold">
                  {betSlipItems.length}
                </span>}

              <button onClick={handleClearBetSlip} className="hidden lg:block" disabled={betSlipItems.length < 1}>
                <img src={binBlack} className="w-5 h-5 text-white" alt="bin" />
              </button>
            </div>
          </div>
          {appStateStore.currentBalance && <div className="pl-2 text-blue bg-gray-100 w-full text-right border-b-2 ">Balance: ${appStateStore.currentBalance?.cashBalance.toFixed(2)} Bonus: ${appStateStore.currentBalance?.bonusBalance.toFixed(2)}</div>}
        </div>

        {isBurgerMenuOpen && <div className={`lg:mt-15 w-full  lg:max-h-[80vh]`}>

          {betSlipItems && betSlipItems.length === 0 && (
            <p className="text-gray-400 text-lg italic  pb-[59vh] sm:mb-60 mx-5 ">
              <strong>To place a bet:</strong> Choose the sport, tournament, and
              event that you would like to bet on. Then select a market by
              clicking the odds that you would like to bet on.
            </p>
          )}
          {betSlipItems && (
            <div className="h-full">
              <div className={`h-full lg:z-14 top-20 lg:absolute inset-y-0 lg:right-0 lg:w-1/6 full lg:pb-2 ${betSlipItems.length===1?"pb-[50vh]":
              betSlipItems.length===2?"pb-[5vh]":'pb-1'} overflow-y-auto max-h-[70vh] lg:max-h-[85vh]`}>
                {betSlipItems.map((mkt, marketNumber) => (
                  <div
                    key={marketNumber}
                    className="border-b-4 border-gray-200 p-2 w-full" >
                    <div>
                      <div>
                        <div className="w-full flex">
                            <span className="lg:text-xl font-bold w-4/5">{mkt.tournamentName}</span>
                          
                          <div className="w-1/5 text-end">
                            <button onClick={() => handleRemoveEvent(mkt)}>
                              <img src={xmark} className="w-5 h-5 text-white" alt="x" />
                            </button>
                          </div>
                        </div>

                        <span className="text-amber-500 md:text-base text-sm">
                          {mkt.categoryName}
                        </span>
                        <br />
                        
                          <span className="text-sm italic font-medium w-4/5 pb-1">
                            {`${new Date(mkt.eventDate).toLocaleDateString('en-UK', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric'
                            })} - ${String(new Date(mkt.eventDate).getHours()).padStart(2, '0')}:${String(new Date(mkt.eventDate).getMinutes()).padStart(2, '0')}`}
                          </span>

                        <div className="w-full flex">
                          <div className="w-5/6">
                            <div className="w-full text-blue-500 md:text-base text-sm italic ">
                              <h2>
                                {appStateStore.currentEventBetType ? appStateStore.currentEventBetType?.betTypeId > 1
                                  && appStateStore.currentEventBetType?.betTypeId < 7 ?
                                  `Open-${mkt.betTypeName}` : `${mkt.betTypeName} - (${mkt.marketNumber}) ${mkt.marketName}` : `${mkt.betTypeName} - (${mkt.marketNumber}) ${mkt.marketName}`}
                              </h2>
                            </div>

                            <div className="w-full md:text-base text-sm">
                              <h2> {appStateStore.currentEventBetType ? appStateStore.currentEventBetType?.betTypeId > 1
                                && appStateStore.currentEventBetType?.betTypeId < 13 ?
                                `${mkt.betTypeName}|${appStateStore.currentBetTypeSpecific ? appStateStore.currentBetTypeSpecific.betTypeSpecificName + "|" : ''}
                                ${mkt.combinations ? mkt.combinations > 1 ? `${mkt.combinations} combinations` : `1 combination` : ''}` :
                                `${mkt.eventName}` : `${mkt.eventName}`}
                              </h2>
                            </div>
                          </div>
                          <div className="flex w-1/4 pb-2 md:text-base pl-8 text-sm font-bold">
                            {mkt.Odd.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-5 pb-2">
                      <BetInputComponent
                        marketItem={mkt}
                        onBetChange={(value) => handleBetChange(value, mkt)}
                      />
                    </div>
                    <div className="w-full flex">
                      <div className="ml-auto flex items-center">
                        <div className="w-2/3 text-blue-500 text-sm italic font-bold pr-2 text-right">
                          Potential Payout:
                        </div>
                        <label className="text-blue-500 text-sm font-bold">R</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            isNaN((Number(mkt.stake) * (Number(mkt.Odd) || 0) + Number(mkt.stake))) ?
                              '' :
                              mkt.stake>0? (Number(mkt.stake) * (Number(mkt.Odd) || 0) + Number(mkt.stake)).toFixed(2):''
                          }
                          className="w-1/3 font-bold text-sm px-2 text-blue-500 italic"
                          placeholder="0.00"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <MultiBetGridComponent   handleStakeChange={handleStakeChange}/>
              </div>
            </div>
          )}

          <div className="lg:fixed relative bottom-0 lg:right-0 sm: w-full lg:w-1/6 bg-green-600 text-white font-bold lg:p-5 p-2 rounded text-center">
            <button
              onClick={() => {handlePlaceBet()}}
              disabled={(!betSlipItems.some(item => item.stake !== undefined && item.stake !== 0)&&
                (appStateStore.multiBetItemList===undefined||
                  (appStateStore.multiBetItemList && !appStateStore.multiBetItemList.some(item=>item.stake!== undefined && item.stake !== 0))))
                  ||(isButtonDisabled)
              }
              className={`bg-green-600 text-white w-full font-bold lg:py-2 lg:px-4 rounded 
              ${!betSlipItems.some(item => item.stake !== undefined && item.stake !== 0)&&
                (appStateStore.multiBetItemList===undefined||
                  (appStateStore.multiBetItemList && !appStateStore.multiBetItemList.some(item=>item.stake!== undefined && item.stake !== 0)))?
                'opacity-50 cursor-not-allowed' : ''}`} >
              <p>Place Bet</p>
            </button>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnHover draggable />
          </div>

        </div>}

      </div>
    </div>
  );
};

export default BetSlipComponent;
