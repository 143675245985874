import React, { useEffect, useState } from "react";
import { differenceInSeconds, isDate, parseISO } from 'date-fns';
import { EventStatus } from "../../types/Types";
import { fetchEventStatus } from "../../services/PunterService";

interface CountdownTimerProps {
  eventDate: string | Date;
  eventStatusId: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ eventDate, eventStatusId }) => {
  const [timeRemaining, setTimeRemaining] = useState<{
    days: number;
    hours: number;
    minutes: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [eventStatus, setEventStatus] = useState<EventStatus[]>()

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const parsedEventDate = isDate(eventDate) ? eventDate : parseISO(eventDate);

      const timeDifference = differenceInSeconds(parsedEventDate, now);

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (3600 * 24));
        const hours = Math.floor((timeDifference % (3600 * 24)) / 3600);
        const minutes = Math.floor((timeDifference % 3600) / 60);

        setTimeRemaining({ days, hours, minutes });
      } else {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0 });
      }
    };
    const fetchData = async () => {
      const response = await fetchEventStatus();
      if (response) {
        // Update state with the first event
        setEventStatus(response)
      }
    };
  fetchData()

    const intervalId = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(intervalId);
    // calculateTimeRemaining();
   

  
  }, [eventDate]);

  let formattedTime = '';
  if (timeRemaining.days > 0) {
    formattedTime = `${timeRemaining.days} day(s)`;
  } else if (timeRemaining.hours > 0) {
    formattedTime = `${timeRemaining.hours}h ${timeRemaining.minutes}m`;
  } else if (timeRemaining.minutes > 0) {
    formattedTime = `${timeRemaining.minutes}m`;
  } else {
    formattedTime = 'Live';
  }

  function updateFormattedTime() {
    let formattedTime = '';

    eventStatus?.forEach(eventStatus => {
        if (eventStatus.eventStatusId === eventStatusId) {
            formattedTime = String(eventStatus.eventStatusName);
        }
    });

    return formattedTime;
}

if (eventStatusId > 1) formattedTime = updateFormattedTime().split(" ")[1];

  return (
    <>
      {formattedTime}
    </>
  )
};

export default CountdownTimer;
