import React, { useContext, useEffect, useState } from "react";
import { fetchCategory, fetchTournament } from "../../services/PunterService";
import { AppContext } from "../../services/ContextProvider";
import { Tournament, Event, Category } from "../../types/Types";
import CountdownTimer from "../Input/CountdownTimerComponent";
import DateSelector from "./MainMenu/DateSelectorComponent";
import magnifyingGlass from "../../Icons/magnifyingGlass.svg";
import angleRight from "../../Icons/angleRight.svg";
import angleDown from "../../Icons/angleDown.svg";
import angleUp from "../../Icons/angleUp.svg";
 
interface HorseRacingViewerProps {
  handleEventClick: (event: Event, tourn: Tournament) => void;
  handleTournamentClick: (tourn: Tournament) => void;
  handleNavigation: (viewNumber: number) => void;
}
 
const HorseRacingViewerComponent: React.FC<HorseRacingViewerProps> = ({ handleEventClick, handleTournamentClick, handleNavigation }) => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [defaultCategory, setDefaultCategory] = useState<Category | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResultsCategory, setSearchResultsCategory] = useState<(Category)[]>([]);
  const [searchResultsTournament, setSearchResultsTournament] = useState<(Tournament)[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('0');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showAllTournaments, setShowAllTournaments] = useState<boolean>(false);
  const [showAdditionalElements, setShowAdditionalElements] = useState<boolean>(false);
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false);
  const [expandedCategoryId, setExpandedCategoryId] = useState<number | null>(null);
  const [isDisplay, setIsDisplay] = useState<boolean>(false)
 
 
  useEffect(() => {
    const fetchData = async () => {
      // Fetch categories if not already fetched
      if (categories.length === 0) {
        const initialCategories = await fetchCategory(1);
        setCategories(initialCategories);
      }
      // Fetch tournaments if default category is not set or if it changes
      if (!defaultCategory && categories.length > 0) {
        const defaultCategory = categories[appStateStore.currentTournament?.categoryId || 0];
        setDefaultCategory(defaultCategory);
        setAppStateStore((prevState) => ({
          ...prevState,
          currentCategory: defaultCategory,
        }));
 
        const tournamentPromises = categories.map(async (category) => {
          const tournaments = await fetchTournament(1, category.categoryId || 0);
          tournaments.forEach((tn) => { tn.categoryName = category.categoryName; });
          return tournaments;
        });
 
        const initialTournaments = await Promise.all(tournamentPromises);
        const flattenedTournaments = initialTournaments.flat();
        setTournaments(flattenedTournaments);
 
        setAppStateStore((prevState) => ({
          ...prevState,
          currentTournamentList: flattenedTournaments,
        }));
      }
      else {
        // Update current category if tournaments are already fetched
        if (tournaments.length > 0) {
          setAppStateStore((prevState) => ({
            ...prevState,
            currentCategory: defaultCategory,
          }));
        }
      }
 
    };
    const today = new Date();
    const day = today.getDate();
    const monthIndex = today.getMonth();
    const year = today.getFullYear();
 
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[monthIndex];
 
    const formatted = `${day} ${monthName} ${year}`;
    setAppStateStore((prevState) => ({
      ...prevState,
      todaysDate: formatted,
    }));
    handleDateSelect(formatted)
    fetchData();
  }, [categories, appStateStore.marketDisable]);
 
 
  //Change letters to lower case
  const handleCategorySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
 
    const filteredCategories = categories.filter((category: Category) =>
      category.categoryName?.toLowerCase().includes(e.target.value.toLowerCase())
    );
 
    setSearchResultsCategory([...filteredCategories]);
  };
 
  const handleTournamentSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
 
    const filteredTournaments = tournaments.filter((tournament: Tournament) =>
      tournament.tournamentName?.toLowerCase().includes(e.target.value.toLowerCase())
    );
 
    setSearchResultsTournament([...filteredTournaments]);
  };
 
  // Change page display
  const handleCategorySelect = (category: Category) => {
    setSearchQuery("");
    setShowDropdown(false);
    setIsDisplay(true)
    if ('categoryId' in category) {
      // Category is selected
      setDefaultCategory(category);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: category,
      }));
    }
  };
 
  const handleTournamentSelect = (tournament: Tournament) => {
    setSearchQuery("");
    setShowDropdown(false);
    if ('tournamentId' in tournament) {
      // Tournament is selected
      setDefaultCategory(tournament);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: tournament,
      }));
    }
  };
 
  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
  };
 
  const handleCategoryClick = async (category: Category) => {
    if (category) {
      const _tournaments = await fetchTournament(1, category.categoryId);
 
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: category,
        currentTournamentList: _tournaments,
      }));
    }
  };
 
  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };
 
  const handleMoreButtonClick = () => {
    setShowAllTournaments(true);
  };
 
  //expands category dropdown
  const handleToggleAdditionalElements = (categoryId: number) => {
    setShowAdditionalElements(true);
    setExpandedCategoryId(categoryId);
    setIsIconClicked(true);
 
    if (categoryId === expandedCategoryId) {
      setShowAdditionalElements(!showAdditionalElements);
      setIsIconClicked(!isIconClicked);
    }
  };
 
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleDropdownClick();
    }
  };

  //expands category dropdown when a date is clicked 
  const handleToggleAdditionalElementsByDate = (categoryId?: number) => {
    if (categoryId) {
      setShowAdditionalElements(true);
      setExpandedCategoryId(categoryId);
      setIsIconClicked(true);
    }
  };

  //expands category dropdown as per whats searched in the search bar 
  const handleToggleDefaultCategoryElements = () => {
    const categoryId = defaultCategory?.categoryId;

    if (!categoryId) return; 

    setShowAdditionalElements(true);
    setExpandedCategoryId(categoryId);
    setIsIconClicked(true);

    if (categoryId === expandedCategoryId) {
        setShowAdditionalElements(!showAdditionalElements);
        setIsIconClicked(!isIconClicked);
    }
};
  return (
    <div className="font-inter h-screen overflow-y-auto max-h-[100vh] lg:pb-0 pb-10">
      <div className="fixed flex justify-end lg:justify-start lg:pl-4 lg:py-4 p-2 lg:w-4/6 w-full bg-gray-50">
 
        {/* Search Bar */}
        <div className="relative lg:w-1/2 w-3/4 bg-white" onKeyDown={handleKeyPress}>
          <input type="text" placeholder="Search for a Country or Race" className="bg-gray-50 w-full py-1 px-2 border border-black rounded-md
           italic text-black text-black placeholder-black focus:outline-none"
            onChange={(e) => {
              handleCategorySearchChange(e);
              handleTournamentSearchChange(e);
            }} value={searchQuery} />
 
          <div className="absolute inset-y-0 right-1 right-3 md:right-[-12] flex items-center ">
            <img src={magnifyingGlass} onClick={handleDropdownClick} alt="magnifying glass" />
          </div>
 
          {/* Search bar functionality */}
          {showDropdown && (
            <div className="absolute z-10 top-full bg-white border border-gray-300 w-full rounded-b-md shadow-lg">
              {searchResultsCategory.length > 0 ? (searchResultsCategory.map((result, index) => (
 
                <div key={index} className="py-1 px-2 cursor-pointer hover:bg-gray-200" onClick={() => {
                  handleCategorySelect(result);
                  handleCategoryClick(result);
                }}>
                  <button>{result.categoryName}</button>
                </div>
              )))
                :
                // Checks length of results to display required results
                searchResultsTournament.length > 0 ? searchResultsTournament.map((result, index) => (
                  <div
                    key={index}
                    className="py-1 px-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      handleTournamentSelect(result); handleTournamentClick(result);
                      result.events && result.events.length > 0 && handleEventClick(result.events[0], result); handleNavigation(1)
                    }}>
                    <button>{result.tournamentName}</button>
                  </div>
                )) :
                  <div className="py-1 px-2">Sorry, no results found.</div>}
            </div>
          )}
        </div>
      </div>
 
      <div className="pt-2">
        <hr className="border-t-1 border-blue-300 lg:mt-16 mt-12 mx-5" />
        <h2 className="text-xl font-bold mb-4 mt-4 ml-10">Up Next</h2>
 
        {/* Tournament Card below Up Next */}
        <div className={`flex overflow-x-auto max-w-full h-[20vh]`} style={{ scrollbarWidth: 'thin', scrollbarColor: '#808080' }}>
          {tournaments && tournaments.slice(0, 2).map((tourn) => (
            // Displays the first two Tournament Cards
            <div key={tourn.tournamentId} className="flex-none bg-gray-200 p-4 sm: w-80 lg:h-[90%] h-[85%] lg:w-1/3 border rounded-lg ml-10 shadow-xl"
              onClick={() => { tourn.events && tourn.events.length > 0 && handleEventClick(tourn.events[0], tourn); handleNavigation(1); }}>
              <div className="flex items-center justify-between mb-1">
                <h3 className="lg:text-xl font-bold text-blue-500" onClick={() => handleTournamentClick(tourn)}>
                  {tourn.tournamentName}
                </h3>
                <p className="lg:text-sm font-semibold text-xs text-amber-500 italic"><CountdownTimer eventDate={tourn.tournamentDate} eventStatusId={1} /></p>
              </div>
              <div className="flex items-center justify-between mb-1">
                <p className="lg:text-sm text-sm">{tourn.categoryName ? tourn.categoryName : defaultCategory?.categoryName}</p>
                <img src={angleRight} className="h-3 w-3 cursor-pointer" alt="angle right" />
              </div>
              <p className="lg:text-lg text-sm pt-2">
                {tourn.tournamentDate.toLocaleString().split('T')[0]} {tourn.tournamentDate.toLocaleString().split('T')[1].split(':')[0]}:
                {tourn.tournamentDate.toLocaleString().split('T')[1].split(':')[1]}
              </p>
            </div>
          ))}
 
          {/* Displays all the hidden cards when More is Click */}
          {showAllTournaments && tournaments
            && tournaments.slice(2).map((tourn) => (
              <div key={tourn.tournamentId} className="flex-none bg-gray-200 p-4 w-80 lg:h-[90%] h-[85%] lg:w-1/3 border rounded-lg ml-10 shadow-xl mr-2"
                onClick={() => { tourn.events && tourn.events.length > 0 && handleEventClick(tourn.events[0], tourn); handleNavigation(1); }}>
                <div className="flex items-center justify-between mb-1">
                  <h3 className="lg:text-xl font-bold text-blue-500" onClick={() => handleTournamentClick(tourn)}>{tourn.tournamentName}</h3>
                  <p className="lg:text-sm font-semibold text-xs text-amber-500 italic"><CountdownTimer eventDate={tourn.tournamentDate} eventStatusId={1} /></p>
                </div>
                <div className="flex items-center justify-between mb-1">
                  <p className="lg:text-sm text-xs">{tourn.categoryName}</p>
                  <img src={angleRight} className="h-3 w-3 cursor-pointer" alt="angle right" />
                </div>
                <p className="lg:text-lg text-sm lg:pt-2">
                  {tourn.tournamentDate.toLocaleString().split('T')[0]} {tourn.tournamentDate.toLocaleString().split('T')[1].split(':')[0]}:
                  {tourn.tournamentDate.toLocaleString().split('T')[1].split(':')[1]}
                </p>
              </div>
            ))}
 
          {/* More Button when not clicked */}
          {!showAllTournaments && tournaments !== undefined && tournaments?.length > 2 && (
            <div className="flex-none w-80 flex items-center p-4 pl-10">
              <button className="rounded-full bg-gray=50 text-black p-4 border border-blue-500" onClick={handleMoreButtonClick}>
                <p>More</p>
                <img src={angleRight} className="h-3 w-3 ml-3.5" alt="angle right" />
              </button>
            </div>
          )}
        </div>
      </div>
 
      {/* Default display when nothing is searched */}
      {!isDisplay ? categories.map((category) => (
        <div key={category.categoryId} className="w-full">
          <hr className="border-t-1 border-blue-300 mt-5 mx-5" />
          <div className="text-base font-bold my-4 flex ">
            <hr className="border-t-1 border-blue-300 mt-5 lg:mx-3" />
            <div className="w-full flex lg:pr-6 ml-6">
              <div className="lg:w-full w-60 flex cursor-pointer" onClick={() => {
                handleToggleAdditionalElements(category.categoryId)
              }}>
                <div className=" lg:w-[40vh] w-3/5">
                  <h3 className="lg:text-xl text-sm font-semibold pl-2.5 lg:pl-0 pt-1">{category.categoryName}</h3>
                </div>
                <div key={category.categoryId}>
                  {isIconClicked && expandedCategoryId === category.categoryId ? (
                    <img src={angleUp} className="h-4 w-4 mt-2" alt="angle up" />
                  ) : (
                    <div className="" key={category.categoryId}>
                      <img src={angleDown} className="h-4 w-4 mt-2" alt="angle down" />
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:pl-0 lg:w-[62vh] w-[12vh] lg:text-base" onClick={() => { handleToggleAdditionalElementsByDate(category.categoryId) }}>
                <DateSelector selectedDate={selectedDate} onSelectDate={handleDateSelect} expandedTournaments={[]} tournamentDate=""
                  dates={tournaments?.filter((tournament) => tournament.categoryId === category.categoryId).map((tournament) => (
                    new Date(tournament.tournamentDate).toLocaleDateString(
                      "en-GB", { day: "2-digit", month: "long", year: "numeric", })) ?? [])} />
              </div>
            </div>
          </div>
 
          {/* Displays CategoryNames, TournamentNames and EventNames */}
          {showAdditionalElements && expandedCategoryId === category.categoryId && tournaments?.filter((tournament) =>
            tournament.categoryId === category.categoryId && selectedDate === new Date(tournament.tournamentDate).toLocaleDateString(
              "en-GB", { day: "2-digit", month: "long", year: "numeric", })).map((tournament) => (
                <div key={tournament.tournamentId} className="bg-gray-200 border rounded-lg lg:mx-10 mx-8 mb-5 shadow-xl pr-5 ">
                  <div className="flex items-center justify-between px-4 py-2">
                    <h3 className="text-2xl font-bold text-blue-500 ml-2">{tournament.tournamentName}</h3>
                    <p className="text-black">
                      <img src={angleRight} className="h-3 w-3 cursor-pointer" onClick={() => {
                        tournament.events && tournament.events.length > 0 &&
                          handleEventClick(tournament.events[0], tournament); handleNavigation(1);
                      }} alt="angle right" />
                    </p>
                  </div>
                  <div className="ml-5 lg:space-x-8 space-x-5 lg:w-[112vh] flex overflow-x-auto border-b border-gray-300 pb-1"
                    style={{ scrollbarWidth: 'thin', scrollbarColor: '#808080' }}>
                    {/* Columns || Event Cards*/}
                    {tournament.events && tournament.events.map((event) => (
                      <div className="flex border border-gray-300 rounded-lg px-4 py-1 mb-3 bg-white" key={event.eventId}
                        onClick={() => { handleEventClick(event, tournament); handleNavigation(1) }}>
                        <button className="lg:w-[4rem] w-[2.9rem] h-[4.6rem] lg:h-[4.2rem]">
                          <div className="lg:text-[17px] text-sm font-bold">{event.eventName}</div>
                          <div className="lg:text-xs text-[12px] text-center">
                            {`${new Date(event.eventDate).getHours()}:${String(new Date(event.eventDate).getMinutes()).padStart(2, "0")}`}
                          </div>
                          <div className="text-[12px] font-bold text-amber-500 italic text-center lg:pb-1 mt-1">
                            <CountdownTimer eventDate={event.eventDate} eventStatusId={event.eventStatusId} />
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
        </div>))
 
        :
 
        //Displays only searched results
        <div key={defaultCategory?.categoryId} className="w-full">
          <hr className="border-t-1 border-blue-300 mt-5 mx-5" />
          <div className="text-base font-bold my-4 flex cursor-pointer">
            <hr className="border-t-1 border-blue-300 mt-5 lg:mx-3" />
            <div className="w-full flex lg:pr-6 ml-6">
              <div className="lg:w-full w-60 flex cursor-pointer" onClick={handleToggleDefaultCategoryElements}>
                <div className="lg:w-[40vh] w-3/5">
                  <h3 className="lg:text-xl text-sm font-semibold pl-6 lg:pl-0">
                    {defaultCategory?.categoryName}
                  </h3>
                </div>
                <div key={defaultCategory?.categoryId}>
                  {isIconClicked && expandedCategoryId === defaultCategory?.categoryId ? (
                    <img src={angleUp} className="h-4 w-4 mt-2" alt="angle up" />
                  ) : (
                    <div key={defaultCategory?.categoryId}>
                      <img src={angleDown} className="h-4 w-4 mt-2" alt="angle down" />
                    </div>
                  )}
                </div>
              </div>
              
              <div className=" lg:pl-0 lg:w-[62vh] w-[12vh] lg:text-base"
                onClick={() => {
                  handleToggleAdditionalElementsByDate(defaultCategory?.categoryId)
                }}>
                <DateSelector selectedDate={selectedDate} onSelectDate={handleDateSelect} expandedTournaments={[]} tournamentDate=""
                  dates={tournaments?.filter((tournament) => tournament.categoryId === defaultCategory?.categoryId).map((tournament) => (
                    new Date(tournament.tournamentDate).toLocaleDateString(
                      "en-GB", { day: "2-digit", month: "long", year: "numeric", })) ?? [])} />
              </div>
            </div>
          </div>
 
          {/* Displays Searched Category */}
          {showAdditionalElements && expandedCategoryId === defaultCategory?.categoryId && tournaments
            ?.filter(
              (tournament) => tournament.categoryId === defaultCategory?.categoryId && selectedDate === new Date(tournament.tournamentDate).toLocaleDateString(
                "en-GB", { day: "2-digit", month: "long", year: "numeric", })).map((tournament) => (
                  <div key={tournament.tournamentId} className="bg-gray-200 border rounded-lg lg:mx-10 mx-8 mb-5 shadow-xl pr-5">
                    <div className="flex items-center justify-between px-4 py-2">
                      <h3 className="text-2xl font-bold text-blue-500 ml-2">{tournament.tournamentName}</h3>
                      <p className="text-black" >
                        <img src={angleRight} className="h-3 w-3  cursor-pointer" onClick={() => {
                          tournament.events && tournament.events.length > 0 &&
                            handleEventClick(tournament.events[0], tournament); handleNavigation(1);
                        }} alt="angle right" />
                      </p>
                    </div>
                    <div className="ml-5 lg:space-x-8 space-x-5 lg:w-[112vh] flex overflow-x-auto border-b border-gray-300 pb-1"
                      style={{ scrollbarWidth: 'thin', scrollbarColor: '#808080' }}>
                      {/* Columns || Event Cards*/}
                      {tournament.events && tournament.events.map((event) => (
                        <div className="flex border border-gray-300 rounded-lg px-4 py-1 mb-3 bg-gray-50" key={event.eventId}>
                          <button className="lg:w-[4rem] w-[2.9rem] h-[4.6rem] lg:h-[4.2rem]"
                            onClick={() => { handleEventClick(event, tournament); handleNavigation(1) }}>
                            <div className="lg:text-[17px] text-sm font-bold">{event.eventName}</div>
                            <div className="lg:text-xs text-[12px] text-center">
                              {`${new Date(event.eventDate).getHours()}:${String(new Date(event.eventDate).getMinutes()).padStart(2, "0")}`}
                            </div>
                            <div className="text-[12px] font-bold text-amber-500 italic text-center lg:pb-1 mt-1">
                              <CountdownTimer eventDate={event.eventDate} eventStatusId={event.eventStatusId} />
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
        </div>
      }
    </div>
  );
};
export default HorseRacingViewerComponent;