import React, { useState, useContext } from "react";
import { AppContext } from "../../../services/ContextProvider";

interface ToggleComponentProps {
  inputValue: string;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({ inputValue }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { appStateStore, setAppStateStore } = useContext(AppContext);

  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
    if (isChecked) {
      setAppStateStore((prevState: any) => ({
        ...prevState,
        currentHorseRacingItem: {isSelected:isChecked}
      }));
  };
}

  return (
    <div className="relative flex">
      <div className="w-1/2 text-right">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer text bg-gray-200"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <div
            className={`relative flex items-center justify-end w-12 h-6 bg-white peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-blue-800 rounded-full 
                         peer dark:bg-gray-700 ${isChecked ? "peer-checked:after:translate-x-7 rtl:peer-checked:after:-translate-x-full" : ""
              } peer-checked:after:border-gray-600 after:content-[''] 
                       after:absolute after:top-[4px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 
                       after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}>
            <span className={`text-xs font-bold pr-1 ${isChecked ? "text-white pr-5" : ""}`}>FLD</span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default ToggleComponent;
