import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../services/ContextProvider";
import { Category } from "../../../types/Types";
import { fetchCategory } from "../../../services/PunterService";

interface HorseRacingViewerProps {
    handleNavigation: (viewNumber: number) => void;
}

const ReturnComponent: React.FC<HorseRacingViewerProps> = ({ handleNavigation }) => {
    const { appStateStore, setAppStateStore } = useContext(AppContext);
    const [categoryList, setCategoryList] = useState<Category[]>([])
    const [backToHome] = useState<Boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            const _categories = await fetchCategory(1);
            setCategoryList(_categories);
            setAppStateStore((appStateStore) => ({
                ...appStateStore,
                backToHome: !backToHome,
              }));
        };
        fetchData();
    }, []);

    const clearEventBetTypes = () =>{
        setAppStateStore((appStateStore) => ({
            ...appStateStore,
            currentEventBetType: undefined,
          }));
    }
    const categoryName = categoryList.find((cat => cat.categoryId === appStateStore.currentTournament?.categoryId)||(cat => cat.categoryId === appStateStore.currentCategory?.categoryId));

    //Return Component
    return (
        <div>
            <div className="w-full flex pl-4 pb-2">
                <div className="w-1/2 flex items-center">
                    <button className="text-center lg:text-md hidden lg:block p-1" onClick={() => {handleNavigation(0); clearEventBetTypes()}}>
                        <span className="text-xl font-bold p-1" >&lt;</span>
                        <span className="ml-2">
                            {appStateStore.currentCategory && appStateStore.currentCategory.categoryId === appStateStore.currentTournament?.categoryId ?
                             appStateStore.currentCategory?.categoryName : `${categoryName?.categoryName}`}
                        </span>
                    </button>
                </div>

                <div className=" lg:w-1/2 flex ">
                    <div className="lg:w-4/5 w-5/7 lg:text-xl text-xs font-bold lg:text-right lg:pt-3 pt-1">
                        <h2 className="p-3">
                            {appStateStore.currentTournament && appStateStore.currentTournament?.tournamentId === appStateStore.currentEvent?.tournamentId
                                ? appStateStore.currentTournament.tournamentName
                                : appStateStore.currentTournament?.tournamentName}</h2>
                    </div>
                    <div className="w-full lg:w-1/5 w-1/7 text-left lg:text-base text-[11px] lg:pt-2 pt-3">
                        <p className="lg:text-[14px]">
                        {appStateStore.currentCategory && appStateStore.currentCategory.categoryId === appStateStore.currentTournament?.categoryId ?
                             appStateStore.currentCategory?.categoryName : `${categoryName?.categoryName}`}
                        </p>
                        <p className="italic lg:text-[14px] ">{new Date(appStateStore.currentEvent?.eventDate || Date.now()).toLocaleDateString('en-UK', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        })}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnComponent;

