import { HorseHeaders} from "../types/Types";
import ToggleComponent from "../components/HorseRacing/MainMenu/ToggleComponent";

export const generateOpenHeaderGrid = (headers: HorseHeaders[], betTypeId?:number, betTypeSpecificId?:number):HorseHeaders[] => {

    if(betTypeId === 13 ){
        if(betTypeSpecificId===1)
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <p>Fixed Place</p>,
                headerNumber: 0,
            }] 
        });
        else{
            headers.forEach((hd) => {
                hd.headerColumns = [{
                    headerName: <p>Open Place</p>,
                    headerNumber: 0,
                }] 
            });
        }
    }
    //Swinger  && Exacta
    if (betTypeId === 2 || betTypeId === 3) {
        headers.forEach((hd) => {
            hd.headerColumns = [{
                headerName: <p className="lg:text-base lg:mr-5 text-xs">Price</p> ,
                headerNumber: 0,
            },
            {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">1st</p>
                <ToggleComponent inputValue="1st"/>
            </div>,
                headerNumber: 1,
            },
            {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">2nd</p>
                <ToggleComponent inputValue="2nd"/>
            </div>,
                headerNumber: 2,
            }
            ]
        });
    }    

     //Trifecta
     else if (betTypeId === 4) {
         headers.forEach((hd) => {
             hd.headerColumns = [{
                 headerName: <p className="lg:text-base lg:mr-5 text-xs">Price</p>,
                 headerNumber: 0,
             },
             {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">1st</p>
                <ToggleComponent inputValue="1st"/>
            </div>,
                headerNumber: 1,
         },
         {
            headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
            <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">2nd</p>
            <ToggleComponent inputValue="2nd"/>
        </div>,
            headerNumber: 2,
             
         },
         {
            headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
            <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">3rd</p>
            <ToggleComponent inputValue="3rd"/>
        </div>,
             headerNumber: 3
         }
         ]
     });
 }

    //Quartet
     else if (betTypeId === 5) {
         headers.forEach((hd) => {
             hd.headerColumns = [{
                headerName: <p className="lg:text-base lg:mr-5 text-xs">Price</p>,
                 headerNumber: 0,
             },
             {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">1st</p>
                <ToggleComponent inputValue="1st"/>
            </div>,
                headerNumber: 1,

             },
             {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">2nd</p>
                <ToggleComponent inputValue="2nd"/>
            </div>,
                headerNumber: 2,
             },
             {
                headerName: <div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">3rd</p>
                <ToggleComponent inputValue="3rd"/>
            </div>,
                headerNumber: 3,
             },
             {
                headerName:<div className="flex flex-col items-center justify-center md:flex-row ">
                <p className="lg:text-md md:pr-2 md:text-center lg:pb-0 pb-1 text-[13px]">4th</p>
                <ToggleComponent inputValue="4th"/>
            </div>,
                headerNumber: 4,
             }
             ]
         });
     }

    return headers;

};