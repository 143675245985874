import { HorseHeaders} from "../types/Types";


export const generateFixedHeaderGrid = (headers: HorseHeaders[], betTypeId?:number, betTypeSpecificId?:number):HorseHeaders[] => {

  //SP Win Header
    if(betTypeId ===1 && betTypeSpecificId === 3) 
    {
            headers.forEach((hd) => {
                hd.headerColumns = [{
                    headerName:<p className="">SP Win</p>,
                    headerNumber: 0,
                }] 
            });
         
    }

    //Open Win Header
    else if(betTypeId ===1 && betTypeSpecificId === 2){
      headers.forEach((hd) => {
        hd.headerColumns = [{
            headerName:<p className="">Open Win</p>,
            headerNumber: 0,
        }] 
    })
    }
    //Fixed Win
    else{
      headers.forEach((hd) => {
        hd.headerColumns = [
          {   
            headerName: <p className="lg:text-base text-xs"> Opening</p> ,
            headerNumber: 0,
          },
          {
            headerName: <p className="lg:text-base text-xs pl-2">Previous</p>,
            headerNumber: 1,
          },
          {
            headerName:<p className="lg:text-base text-xs pl-2">Win</p>,
            headerNumber: 2,
           
          },
          {
            headerName:<p className="lg:text-base text-xs pl-2">Place</p>,
            headerNumber: 3,
            
          },
        ];
      });
    }
    return headers;
}