import { Market } from "../types/Types";

export const generateOpenBettingGrid = (markets: Market[], betTypeId?: number, betTypeSpecificId?: number,): Market[] => {

    if (betTypeId === 13 && betTypeSpecificId === 2) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: " ", 
                columnNumber: 0,
                columnOdd: mk.currentOdd,
                columnMarketNumber: mk.marketNumber,
            }]
         });
    }

    else if (betTypeId === 13 && betTypeSpecificId === 1) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: " ", 
                columnNumber: 0,
                columnOdd: mk.Odd,
                columnMarketNumber: mk.marketNumber,
            }]
         });
    }

    
    //Swinger
    else if (betTypeId === 2) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: "Price",
                columnNumber: 0,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "1st",
                columnNumber: 1,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "2nd",
                columnNumber: 2,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            }
            ]
        });
    }

    //Exacta
    else if (betTypeId === 3) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: "Price",
                columnNumber: 0,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected: false
            },
            {
                columnName: "1st",
                columnNumber: 1,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected: false
            },
            {
                columnName: "2nd",
                columnNumber: 2,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected: false
            }
            ]
        });
    }

    //Trifecta
    else if (betTypeId === 4) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: "Price",
                columnNumber: 0,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "1st",
                columnNumber: 1,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "2nd",
                columnNumber: 2,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "3rd",
                columnNumber: 3,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            }
            ]
        });
    }

    //Quartet
    else if (betTypeId === 5) {
        markets.forEach((mk) => {
            mk.columns = [{
                columnName: "Price",
                columnNumber: 0,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
            },
            {
                columnName: "1st",
                columnNumber: 1,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected:false
            },
            {
                columnName: "2nd",
                columnNumber: 2,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected:false
            },
            {
                columnName: "3rd",
                columnNumber: 3,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected:false
            },
            {
                
                columnName: "4th",
                columnNumber: 4,
                columnOdd: mk.marketNumber,
                columnMarketNumber: mk.marketNumber,
                isSelected:false
            }
            ]
        });
    }

    return markets;

};