import {LegItem, MultiBetSlipItem, BetSlipItem } from "../types/Types";


export const generateCombinationBets = (combinations: number, multiBetItem: MultiBetSlipItem, betSlipItemArray: BetSlipItem[] ) => {
   
        let returnBets: MultiBetSlipItem[] = [];
        let legs: LegItem[] = [];
        let legNo:number = 1;
        betSlipItemArray.forEach(bl=> {
            let leg:LegItem = {
                legNo: legNo,
                eventId:0,
                marketNumber: 0,
                betTypeId: 0,
                dataProviderId: 0,
                odd: bl.Odd,
                stake: 0,
                payout: 0,
                sportId: 0 ,       
                legSelection: [] 
            };
            legNo++;
            legs.push(leg);
        }); 

        // Return straight multiple + Yankee
        if (combinations === 0) {
            multiBetItem.betCount = 1;
            multiBetItem.totalOdds = calcTotalOdds(betSlipItemArray);
            return multiBetItem;
        }

        
        if (combinations === 1)  {            
            multiBetItem.betCount = 1;
            for(let x:number = 2; x < legs.length; x++)
            {                
                const legOfXmultiples = fixedLegCombinations(legs, x);
                multiBetItem.betCount = multiBetItem.betCount + legOfXmultiples.length;
            }
            multiBetItem.totalOdds = calcTotalOdds(betSlipItemArray);
            return multiBetItem;
        }

        // Return any other multiple with the same number of legs
        if (legs && legs.length > 0 && combinations > 1)  {
            const legOfXmultiples = fixedLegCombinations(legs, combinations);
            multiBetItem.betCount = legOfXmultiples.length;
            multiBetItem.totalOdds = 0;
            legOfXmultiples.forEach(currLeg => {
                let totalOdds:number = calcTotalOddsOnLegs(currLeg);
                if(multiBetItem.totalOdds !== undefined && multiBetItem.totalOdds < totalOdds ){
                    multiBetItem.totalOdds = totalOdds;
                }
            });            
            return multiBetItem;
        }
};


export const calcTotalOdds = (legs: BetSlipItem[]): number =>
{ 
    let accumulator:number = 1;
    legs.forEach( l=> {
        accumulator = accumulator * (l.Odd+1);
        if(l.Odd === 0) accumulator = 0;
    }) 
    accumulator =accumulator - 1;
    return accumulator;
};

export const calcTotalOddsOnLegs = (legs: LegItem[]): number =>
{ 
    let accumulator:number = 1;
    legs.forEach( l=> {
        accumulator = accumulator * (l.odd+1);
        if(l.odd === 0) accumulator = 0;
    }) 
    accumulator =accumulator - 1;
    return accumulator;
};

export const fixedLegCombinations = (legs: LegItem[], r: number): LegItem[][] => {
    const combinations: LegItem[][] = [];
    // function to generate combinations
    const generateCombinations = (currentCombo: LegItem[], startIndex: number): void => {
        if (currentCombo.length === r) {
            combinations.push([...currentCombo]);
            return;
        }
        for (let i = startIndex; i < legs.length; i++) {
            const legToAdd = legs.find(l => l.legNo === i + 1);
            if (legToAdd) {
                currentCombo.push(legToAdd);
                generateCombinations(currentCombo, i + 1);
                currentCombo.pop();
            }
        }
    };
    generateCombinations([], 0);
    return combinations;
};

export const calcPayout = (stake: number, betCount?: number, totalOdds?: number): number | undefined => {
    let payout: number | undefined;
    if (totalOdds  && betCount && totalOdds > 0 && betCount > 0) {
        const stakePerLeg = stake / betCount;
        payout= stakePerLeg * (totalOdds + 1); 
    }
    return payout;
};