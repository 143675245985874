import React from 'react';
import { BrowserRouter, Routes, Route,} from "react-router-dom"; 
import HorseRacingPage from './pages/HorseRacingPage';
import { AppState } from './types/Types'; 
import {AppContext } from './services/ContextProvider';
 

const App: React.FC = () => {
  const [appStateStore, setAppStateStore] = React.useState<AppState>({});
 

 

  return (   
    <AppContext.Provider  value={{ appStateStore, setAppStateStore }}>      
      <BrowserRouter>
        <div className="app">
          <div className="flex w-full flex-col lg:flex-row">
          <Routes>          
                <Route path="/" element={ <HorseRacingPage/>}/>  
                    
               
          </Routes> 
          </div>
        </div>
      </BrowserRouter> 
    </AppContext.Provider>
  );
};

export default App;
